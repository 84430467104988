import { getFunctions, httpsCallable } from "firebase/functions";
import { useState } from "react";
const functions = getFunctions();

function NewElectricExplorer() {
  const [name, setName] = useState("");
  const [familyname, setFamilyname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const callFunctions = (e) => {
    e.preventDefault();
    const addSubscriber = httpsCallable(functions, "addSubscriber");
    addSubscriber({
      name: name,
      lastname: familyname,
      phone: phone,
      email: email,
    })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="lg:min-h-[750px] bg-[url('../public/images/new-electric-explorer-hero-desktop.jpg')]  bg-center lg:bg-center p-0 lg:p-16 bg-cover bg-no-repeat">
        <h1 className="hidden text-3xl lg:block font-fordMedium text-fordBlue text-end">
          Преоткрийте изследователската дейност
        </h1>
        <h2 className="hidden text-2xl lg:block text-fordBlue text-end">
          с новият изцяло електрически
        </h2>
        <p className="hidden text-3xl uppercase lg:block font-fordMedium text-fordBlue text-end">
          Ford Explorer
        </p>
      </div>
      <div className="mx-auto my-4 lg:hidden bg-[url('../public/images/new-electric-explorer-hero-mobile.jpg')] bg-contain bg-no-repeat bg-center pt-4 pb-72">
        <h1 className="px-4 text-xl text-center font-fordMedium text-fordBlue">
          Преоткрийте изследователската дейност
        </h1>
        <h2 className="text-center text-ZZxl text-fordBlue">
          с новият изцяло електрически
        </h2>
        <p className="text-3xl text-center uppercase font-fordMedium text-fordBlue">
          Ford Explorer
        </p>
      </div>
      <h2 className="mt-16 text-3xl leading-normal tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
        <span className="font-fordMedium">Най-добрият</span>
        <br />
        изцяло електрически SUV
        <br />
        <span className="font-fordMedium">е тук</span>
      </h2>
      <p className="mx-4 mt-8 tracking-widest text-center lg:mx-32">
        Готов за приключения. Забележителен дизайн. Бързо зареждане. С
        изключителен комфорт и усъвършенствани технологии за подпомагане на
        водача. Изследователят Explorer. Преосмислете значението на
        приключението.
      </p>

      <div className="px-8 py-16 mt-16 lg:mt-32 lg:px-24 bg-fordBlue">
        <h2 className="text-3xl leading-normal tracking-widest text-center text-white lg:text-xl font-fordLight">
          Присъединете се към нас, за да преживеете приключението
        </h2>
        <p className="mx-4 mt-8 text-xl tracking-widest text-center text-white uppercase lg:mx-32">
          СЛЕДЕТЕ НАШАТА ИНФОРМАЦИЯ
          <br />
          ПОЛУЧАВАЙТЕ ПЪРВИ НАЙ-НОВИТЕ НОВИНИ И АКТУАЛИЗАЦИИ ЗА ИЗЦЯЛО
          ЕЛЕКТРИЧЕСКИЯ EXPLORER.
        </p>
      </div>

      <div className="my-16 lg:my-32" id="form-block">
        <h2 className="text-3xl tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
          Заявете своя интерес за EXPLORER новини!
        </h2>
        {/* <form
          method="POST"
          action="https://script.google.com/macros/s/AKfycbxZjOjXCIPtssaQsUAU-lIYsHbJA_C4vLz3KBvpG0T5pqqfIVk0UQ6I9kpJimPk_ZaG/exec"
          id="the-form"
          className="max-w-2xl mx-4 mt-8 space-y-6 lg:mx-auto"
        > */}
        <form className="max-w-2xl mx-4 mt-8 space-y-6 lg:mx-auto">
          <div className="mt-4">
            <label htmlFor="Name" className="tracking-wider text-gray-400">
              Име
            </label>
            <div>
              <input
                type="text"
                name="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                placeholder="Иван"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="Familia" className="tracking-wider text-gray-400">
              Фамилия
            </label>
            <div>
              <input
                type="text"
                name="Familia"
                value={familyname}
                onChange={(e) => setFamilyname(e.target.value)}
                required
                placeholder="Петров"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="Email" className="tracking-wider text-gray-400">
              Имейл
            </label>
            <div>
              <input
                type="email"
                name="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                placeholder="email@email.com"
              />
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="Phone" className="tracking-wider text-gray-400">
              Телефон
            </label>
            <div>
              <input
                type="tel"
                name="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="+359 876 123 456"
              />
            </div>
          </div>
          <p className="mt-8 text-2xl uppercase">
            Маркетинг комуникация от Ford България
          </p>
          <small className="text-gray-500">
            Мото-Пфое ще използва вашите данни, за да ви изпраща новини за
            новият Ford Explorer и премиерата му в България. За повече
            информация относно как ползваме предоставените от вас данни,
            споделянето им и вашите права, ви молим да посетите страницата ни{" "}
            <a
              href="https://ford.bg/terms-and-privacy-policy-hub"
              target="_blank"
              className="underline text-fordBlue"
              rel="noreferrer"
            >
              Политика за поверителност
            </a>
            .
          </small>
          <br />
          <p className="mt-4 font-fordMedium">
            Съгласие за ползване на Данните ви за маркетингови цели от Ford и
            Moto-Pfohe
          </p>
          <div className="flex item-center">
            <input
              type="checkbox"
              className="mt-2"
              name="telefon"
              id="telefon"
            />
            <label htmlFor="telefon" className="block mt-1 ml-2">
              По телефон
            </label>
          </div>
          <div className="flex item-center">
            <input type="checkbox" className="mt-2" name="sms" id="sms" />
            <label htmlFor="sms" className="block mt-1 ml-2">
              SMS
            </label>
          </div>
          <div className="flex item-center">
            <input type="checkbox" className="mt-2" name="email" id="email" />
            <label htmlFor="email" className="block mt-1 ml-2">
              По имейл
            </label>
          </div>
          <button
            onClick={callFunctions}
            className="px-12 py-2 mx-auto mt-4 tracking-widest text-white rounded-full bg-fordBlue font-fordMedium"
          >
            Заявете своя интерес
          </button>
          <div className="mt-4">
            <a
              href="https://ford.bg/cars/new-explorer"
              className="px-12 py-3 tracking-widest border rounded-full text-fordBlue border-fordBlue font-fordMedium"
            >
              Научете повече
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewElectricExplorer;
