import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";
import { useState } from "react";
import { GetInTouch } from "../components/GetInTouch";

export default function TransitCourier() {
  const model = "Transit Courier";
  const copy = "l.koleva@motopfohe.bg";
  document.title = "Новият Ford Transit Courier";

  const [showModal, setShowModal] = useState(false);
  const [zapitvane, setZapitvane] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setZapitvane(false);
  };

  return (
    <div>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
      >
        <div className="bg-[url(../public/images/new-transit-courier/2023_FORD_TRANSIT_COURIER_EXTERIOR_01.jpg)] pt-4 lg:pt-24 min-h-[240px] lg:min-h-[810px] bg-cover bg-no-repeat">
          {/* <img
            src="./images/new-transit-courier/2023_FORD_TRANSIT_COURIER_EXTERIOR_01 (1).jpg"
            alt=""
          /> */}
          <h2 className="ml-4 text-left text-white lg:pl-48 lg:text-5xl font-fordMedium">
            ReFORDolution
          </h2>
          <h3 className="ml-4 text-left text-white lg:mt-4 lg:pl-48 lg:text-3xl">
            All-New Transit Courier – революционна производителност
          </h3>
          <h2 className="ml-4 text-left text-white lg:pl-48 lg:mt-4 lg:text-3xl font-fordMedium">
            Само за 33 000 лв. без ДДС
          </h2>
        </div>
        <div>
          <img
            src="./images/new-transit-courier/TransitCourier-NCAP_16x9.jpg"
            alt=""
          />
        </div>
      </Carousel>
      <section className="p-4 lg:p-12 lg:px-36">
        <p className="text-xl leading-8 tracking-wide text-center">
          Изцяло новият Transit Courier издига сегмента си до ново ниво на
          безкомпромисна производителност - с по-висок товарен капацитет,
          множество екстри, съчетавайки качествата на Transit гамата на Ford с
          високо стандартно ниво и 7 години гаранция.
        </p>
      </section>
      <section className="p-8 lg:p-12 lg:px-64 bg-fordBlue">
        <p className="text-2xl text-center text-white lg:font-fordMedium">
          All-New Transit Courier е единственият лекотоварен автомобил с
          платинена оценка за безопасност на Euro NCAP през 2023
        </p>
      </section>
      <section>
        <div className="flex flex-col lg:flex-row">
          <img
            src="../images/new-transit-courier/ford-transit-courier-load-space.jpg"
            alt=""
            className="lg:w-1/2"
          />
          <div className="p-4 lg:p-16 lg:w-1/2 bg-gray-50">
            <h3 className="text-xl tracking-wide uppercase">
              Товарен капацитет
            </h3>
            <p className="mt-4">
              Новият Transit Courier вече е по-дълъг, по-широк и по-висок.
              Дължината на товарния отсек от 1,8 м, с разстояние между арките на
              колелата от 1,22 м, е проектирана така, че да побира два
              европалета и максимален полезен товар от 850 кг. Повишаването на
              височината на тавана до 1,25 м допринася за големия обем.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="p-4 lg:p-16 lg:w-1/2 ">
            <h3 className="text-xl tracking-wide uppercase">
              Свързаност и технологии
            </h3>
            <p className="mt-4">
              Transit Courier е снабден с усъвършенствани системи в помощ на
              водача, Интелигентните системи за подпомагане на шофирането,
              включително адаптивен круиз контрол с поддържане на лентата за
              движение, асистент за предотвратяване на сблъсък, интелигентен
              асистент за намаляване на скоростта и асистент за паркиране -
              правят шофирането и работата по- лесни, по-интелигентни и
              по-безопасни. Изцяло новият Transit Courier постигна най-високия
              резултат в тестовете на Euro NCAP за безопасност на лекотоварни
              автомобили - феноменалните 93% и печели най-високата възможна
              платинена оценка за 2023г.
            </p>
          </div>
          <img
            src="../images/new-transit-courier/ford-transit-courier-connectivity.jpg"
            alt=""
            className="lg:w-1/2"
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <img
            src="../images/new-transit-courier/ford-transit-courier-interior.jpg"
            alt=""
            className="lg:w-1/2"
          />
          <div className="p-4 lg:p-16 lg:w-1/2 bg-gray-50">
            <h3 className="text-xl tracking-wide uppercase">
              Високо стандартно ниво на оборудване
            </h3>
            <p className="mt-4">
              Новият Transit Courier е екипиран стандартно с климатична система,
              8“ цветен тъчскийн дисплей, SYNC 4, асистент за предотвратяване на
              сблъсък, модем, разпознаване на пътни знаци, система за задно
              паркиране, 6 въздушни възглавници, 7 години гаранция и цени,
              започващи от 33 000 лв. без ДДС за версия с Ecoboost двигател и
              100 к.с.
            </p>
          </div>
        </div>
      </section>
      <section className="p-8 lg:p-12 lg:px-36 bg-fordBlue">
        <p className="text-2xl text-center text-white font-fordMedium">
          Задава се революционно решение за всички нужди на бизнеса – Новият
          Transit Courier!
          <br />
          Безкомпромисни възможности, издръжливост и продуктивност.
        </p>
      </section>
      <section className="flex flex-col items-center px-4 py-12 lg:justify-center lg:p-16 lg:flex-row">
        <a
          href="https://ford.bg/commercial_cars/new-transit-courier"
          target="_blank"
        >
          <button className="px-12 py-2 mx-8 border rounded-full border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж повече за модела
          </button>
        </a>
        <button
          className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
          onClick={() => {
            setShowModal(true);
            setZapitvane(true);
            window.scrollTo(0, 0);
          }}
        >
          Свържи се с търговец
        </button>
      </section>
      <img
        src="./images/new-transit-courier/ford-transit-courier-front-grill.jpg"
        alt=""
      />
      <section className="p-4 -mb-8 lg:px-24 bg-gray-50">
        <p className="text-sm text-center">
          * Цената е валидна за Transit Courier Van ниво на оборудване Trend,
          двигател 1.0L EcoBoost, 100 к.с., 6 степенна механична скоростна
          кутия, с предно предаване и важи само за юридически лица.
        </p>
      </section>
      {showModal && zapitvane && (
        <Modal handleClose={handleClose}>
          <GetInTouch model={model} copy={copy} />
        </Modal>
      )}
    </div>
  );
}
