import { doc, collection, getDocs, setDoc } from "firebase/firestore";
import { db, storage } from "../firebase/config";

import { useLoaderData } from "react-router-dom";

import ExportTableAsCSV from "../components/ExportTableAsCSV";

function TestdriveRequests() {
  const usersTestdriveList = [];
  const result = useLoaderData(usersTestdriveLoader);

  result.forEach((doc) => {
    usersTestdriveList.push({ id: doc.id, ...doc.data() });
    usersTestdriveList.sort((a, b) => b.date - a.date);
  });

  return (
    <div className="container p-8 mx-auto">
      <ExportTableAsCSV
        tableData={document.querySelector(".testdriveTable")}
        fileName="Testdrive_Requests.csv"
      />
      <div className="grid grid-cols-11 gap-0 p-2 text-white bg-fordBlue testdriveTable">
        <h4 className="col-span-2">Име</h4>
        <h4 className="col-span-2 text-center">Имейл</h4>
        <h4 className="col-span-2 text-center">Телефон</h4>
        <h4 className="text-center ">Модел</h4>
        <h4 className="text-center">Оферта</h4>
        <h4 className="text-center">Град</h4>
        <h4 className="text-center">Бюлетин</h4>
        <h4 className="text-center ">Дата</h4>
      </div>
      {usersTestdriveList &&
        usersTestdriveList.map((user) => (
          <div
            className="grid grid-cols-11 gap-2 p-2 border-b text-fordBlue even:bg-gray-50 hover:bg-gray-200 offerTableRow"
            key={user.id}
          >
            <p className="col-span-2">{user.name}</p>
            <p className="col-span-2 text-center">{user.email}</p>
            <p className="col-span-2 text-center">{user.phone}</p>
            <p className="text-center ">{user.userModel}</p>
            <p className="text-center">{user.userOffer}</p>
            <p className="text-center ">{user.dealership}</p>
            {user.newsletter ? (
              <p className="text-center">Да</p>
            ) : (
              <p className="text-center">Не</p>
            )}
            <p className="text-center ">
              {user.date.toDate().toLocaleDateString("bg-BG")}
            </p>
          </div>
        ))}
    </div>
  );
}

export default TestdriveRequests;

export const usersTestdriveLoader = async () => {
  const querySnapshot = await getDocs(collection(db, "usersTestdrive"));
  return querySnapshot;
};
