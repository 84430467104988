import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/config";

import { useState } from "react";

export default function FirebaseQueryTest() {
  const list = [];
  const [listF, setListF] = useState([]);

  const filter = async () => {
    const carsRef = collection(db, "cars");
    const q = query(carsRef, where("model", "==", "PUMA"));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      list.push(doc.data());
      //   console.log(list);
      //   setListF(...list);
      setListF((list) => [...list, doc.data()]);
      console.log(listF);
    });
  };

  return (
    <div>
      <h1>FirebaseQueryTest</h1>
      <button
        onClick={filter}
        className="px-8 py-2 border rounded-md border-fordBlue"
      >
        Filter
      </button>
      {listF.length > 0 && (
        <div>
          <h1>LIST:</h1>
          <ul>
            {/* {listF.map((item) => {
          <li key={item.id}>{item.id}</li>;
        })} */}
          </ul>
        </div>
      )}
    </div>
  );
}
