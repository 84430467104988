import React, { useEffect, useState } from "react";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { db } from "../firebase/config"; // Вземаме Firestore
import {
  collection,
  addDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
  doc,
  where,
} from "firebase/firestore";
import Modal from "./Modal"; // Импортираме компонента за модал

const ImageManager = () => {
  const [desktopImages, setDesktopImages] = useState([]);
  const [mobileImages, setMobileImages] = useState([]);
  const [activeTab, setActiveTab] = useState("desktop");
  const [newImages, setNewImages] = useState([]); // За качване на нови изображения
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false); // Управление на модала за качване
  const [selectedImages, setSelectedImages] = useState([]); // За маркирани изображения
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true); // Деактивираме бутона за изтриване по подразбиране

  const storage = getStorage();

  // Функция за извличане на изображенията от Firestore
  const fetchImages = async (folder, setImages) => {
    const imagesCollectionRef = collection(db, `images/${folder}/files`);
    const q = query(imagesCollectionRef, orderBy("uploadTime", "desc"));
    const snapshot = await getDocs(q);
    const imageList = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setImages(imageList);
  };

  useEffect(() => {
    fetchImages("desktop", setDesktopImages);
    fetchImages("mobile", setMobileImages);
  }, []);

  // Функция за качване на ново изображение
  const handleUpload = async () => {
    if (!newImages.length) return;

    const folder = activeTab === "desktop" ? "desktop" : "mobile";

    try {
      await Promise.all(
        newImages.map(async (image) => {
          const imageRef = ref(
            storage,
            `landing-images/${folder}/${image.name}`
          );
          await uploadBytes(imageRef, image);
          const downloadURL = await getDownloadURL(imageRef);

          // Добавяне на данни във Firestore
          const imagesCollectionRef = collection(db, `images/${folder}/files`);
          await addDoc(imagesCollectionRef, {
            url: downloadURL,
            fullPath: imageRef.fullPath,
            uploadTime: new Date(),
          });

          if (folder === "desktop") {
            setDesktopImages((prev) => [
              { url: downloadURL, uploadTime: new Date() },
              ...prev,
            ]);
          } else {
            setMobileImages((prev) => [
              { url: downloadURL, uploadTime: new Date() },
              ...prev,
            ]);
          }
        })
      );

      // Изчистваме избраните изображения след качване
      setNewImages([]);

      // Затваряме модала след качване
      setIsUploadModalOpen(false);

      // Презареждаме изображенията, за да се появят новите файлове
      if (folder === "desktop") {
        fetchImages("landing-images/desktop", setDesktopImages);
      } else {
        fetchImages("landing-images/mobile", setMobileImages);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // Функция за маркиране на изображения
  const toggleImageSelection = (url) => {
    setSelectedImages((prevSelected) =>
      prevSelected.includes(url)
        ? prevSelected.filter((imageUrl) => imageUrl !== url)
        : [...prevSelected, url]
    );
  };

  useEffect(() => {
    setIsDeleteDisabled(selectedImages.length === 0);
  }, [selectedImages]);

  // Функция за изтриване на избрани изображения
  const handleDeleteSelectedImages = async () => {
    if (!selectedImages.length) return;

    try {
      await Promise.all(
        selectedImages.map(async (image) => {
          const imageRef = ref(storage, image.fullPath); // Използваме пълната пътека за изтриване
          await deleteObject(imageRef); // Изтриване на изображението от Firebase Storage

          // Премахваме изображението и от Firestore
          const imagesCollectionRef = collection(
            db,
            `images/${activeTab}/files`
          );
          const q = query(
            imagesCollectionRef,
            where("fullPath", "==", image.fullPath)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async (docSnapshot) => {
            await deleteDoc(docSnapshot.ref);
          });

          // Обновяване на списъка с изображения
          if (activeTab === "desktop") {
            setDesktopImages((prev) =>
              prev.filter((img) => img.fullPath !== image.fullPath)
            );
          } else {
            setMobileImages((prev) =>
              prev.filter((img) => img.fullPath !== image.fullPath)
            );
          }
        })
      );

      setSelectedImages([]); // Изчистване на избраните изображения след изтриване
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  // Функция за рендиране на изображенията според активния таб
  const renderImages = (images) => (
    <div className="grid grid-cols-4 gap-4">
      {images.map((image, index) => (
        <div key={index} className="relative p-2 border">
          <img
            src={image.url}
            alt={`image-${index}`}
            className="w-full h-auto"
          />
          <div className="mt-2">
            <input
              type="checkbox"
              checked={selectedImages.includes(image.url)}
              onChange={() => toggleImageSelection(image.url)}
            />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex">
      {/* Лява част с навигационни табове */}
      <aside className="w-1/4">
        <div className="p-4 bg-gray-100 border-r">
          <h3 className="mb-4 text-lg tracking-wider uppercase">
            Изберете папка
          </h3>
          <ul className="mt-4">
            <li>
              <button
                className={`w-full text-left p-2 rounded ${
                  activeTab === "desktop"
                    ? "bg-fordBlue text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setActiveTab("desktop")}
              >
                Десктоп изображения
              </button>
            </li>
            <li className="mt-2">
              <button
                className={`w-full text-left p-2 rounded ${
                  activeTab === "mobile"
                    ? "bg-fordBlue text-white"
                    : "bg-gray-200"
                }`}
                onClick={() => setActiveTab("mobile")}
              >
                Мобилни изображения
              </button>
            </li>
          </ul>
        </div>
        <div>
          <button
            className="w-full px-4 py-2 mt-4 border rounded text-fordBlue border-fordBlue"
            onClick={() => setIsUploadModalOpen(true)}
          >
            Качи ново изображение
          </button>
        </div>
        <div className="mt-4">
          <button
            className="w-full px-4 py-2 border rounded text-fordRed hover:text-white border-fordRed hover:bg-fordRed disabled:opacity-50"
            disabled={isDeleteDisabled}
            onClick={handleDeleteSelectedImages}
          >
            Изтрий избраните изображения
          </button>
        </div>
      </aside>

      <div className="w-3/4 p-4">
        <h2 className="mb-4 text-2xl tracking-wider uppercase">
          {activeTab === "desktop"
            ? "Изображения за десктоп"
            : "Изображения за мобилни устройства"}
        </h2>
        {activeTab === "desktop"
          ? renderImages(desktopImages)
          : renderImages(mobileImages)}
      </div>

      {/* Модал за качване на изображение */}
      {isUploadModalOpen && (
        <Modal handleClose={() => setIsUploadModalOpen(false)}>
          <h2 className="p-4 text-lg text-white bg-fordBlue">
            Качи изображение в папка{" "}
            {activeTab === "desktop" ? "Десктоп" : "Мобилни устройства"}
          </h2>

          {/* Зона за Drag & Drop */}
          <div
            className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed cursor-pointer"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              setNewImages(Array.from(e.dataTransfer.files)); // Обработка на файловете при drop
            }}
            onClick={() => document.getElementById("hiddenFileInput").click()} // Клик върху зоната активира скрития бутон
          >
            <p className="mt-2 text-gray-500">
              Плъзнете и пуснете изображения тук или кликнете, за да изберете от
              компютъра си.
            </p>
          </div>

          {/* Показване на избраните файлове */}
          {newImages.length > 0 && (
            <div className="mt-4">
              <h4 className="mb-2 text-lg font-semibold text-gray-700">
                Избрани файлове:
              </h4>
              <ul>
                {newImages.map((file, index) => (
                  <li key={index} className="text-gray-600">
                    {file.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Скрит Input за файлове */}
          <input
            type="file"
            id="hiddenFileInput"
            multiple
            onChange={(e) => setNewImages(Array.from(e.target.files))} // Обработка на файловете при избор
            style={{ display: "none" }} // Скриваме input полето
          />

          {/* Бутон за качване, поставен извън зоната за drag & drop */}
          <button
            className="w-full px-4 py-2 mt-4 border rounded text-fordBlue border-fordBlue hover:bg-fordBlue hover:text-white"
            onClick={handleUpload} // Изпраща файловете
          >
            Качи
          </button>
        </Modal>
      )}
    </div>
  );
};

export default ImageManager;
