import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

import { useLoaderData } from "react-router-dom";

const functions = getFunctions();

const callFunctions = (e) => {
  e.preventDefault();
  const checkDatabase = httpsCallable(functions, "checkDatabase");
  checkDatabase({})
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default function TestNavision() {
  const records = useLoaderData();

  return (
    <div className="container grid grid-cols-3 gap-4 p-16 mx-auto my-16">
      {records.map((record) => {
        return (
          <>
            <div
              key={record.id}
              className="p-4 border rounded-md border-fordBlue"
            >
              <p>{record.id}</p>
              <p className="font-bold">{record.model}</p>
              <p>{record.trim}</p>
              <p>{record.engine}</p>
              <p>{record.color}</p>
              <p>{record.new_price} лв.</p>
            </div>
            <button onClick={callFunctions}>Check Database</button>
          </>
        );
      })}
    </div>
  );
}

export const testNavisionLoader = async () => {
  let results = [];
  const querySnapshot = await getDocs(collection(db, "test-navision"));
  querySnapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });
  return results;
};
