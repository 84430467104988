import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";

import { useLoaderData } from "react-router-dom";

import ExportTableAsCSV from "../components/ExportTableAsCSV";

function OfferRequests() {
  const usersList = [];
  const result = useLoaderData(usersLoader);

  result.forEach((doc) => {
    usersList.push({ id: doc.id, ...doc.data() });
    usersList.sort((a, b) => b.date - a.date);
  });

  return (
    <div className="container py-8 mx-auto">
      <ExportTableAsCSV
        tableData={document.querySelector(".oferrsTable")}
        fileName="Offer_Requests.csv"
      />
      <div className="grid grid-cols-12 gap-0 p-2 text-white bg-fordBlue oferrsTable">
        <h4 className="col-span-2 text-left">Име</h4>
        <h4 className="col-span-2 text-center">Имейл</h4>
        <h4 className="col-span-2 text-center">Телефон</h4>
        <h4 className="text-center ">Модел</h4>
        <h4 className="text-center">Оферта</h4>
        <h4 className="text-center">Представителство</h4>
        <h4 className="text-center">Лизинг</h4>
        <h4 className="text-center">Бюлетин</h4>
        <h4 className="text-center ">Дата</h4>
      </div>
      {usersList &&
        usersList.map((user) => (
          <div
            className="grid grid-cols-12 gap-2 p-2 border-b text-fordBlue even:bg-gray-50 hover:bg-gray-200 offerTableRow"
            key={user.id}
          >
            <p className="col-span-2 text-left">{user.name}</p>
            <p className="col-span-2 text-center">{user.email}</p>
            <p className="col-span-2 text-center">{user.phone}</p>
            <p className="text-center ">{user.userModel}</p>
            <p className="text-center">{user.userOffer}</p>
            <p className="text-center ">
              {user.dealership.replaceAll("@motopfohe.bg", "")}
            </p>
            {user.leasing ? (
              <p className="text-center">Да</p>
            ) : (
              <p className="text-center">Не</p>
            )}
            {user.newsletter ? (
              <p className="text-center">Да</p>
            ) : (
              <p className="text-center">Не</p>
            )}
            <p className="text-center ">
              {user.date.toDate().toLocaleDateString("bg-BG")}
            </p>
          </div>
        ))}
    </div>
  );
}

export default OfferRequests;

export const usersLoader = async () => {
  const querySnapshot = await getDocs(collection(db, "usersQuote"));
  return querySnapshot;
};
