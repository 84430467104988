import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Импортирай useNavigate
import {
  collection,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/config"; // Импортирай Firestore
import Modal from "../components/Modal"; // Импортирай компонента за модал

const LandingPageList = () => {
  const navigate = useNavigate(); // Инициализирай useNavigate
  const [landingPages, setLandingPages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Управление на модала
  const [pageToDelete, setPageToDelete] = useState(null); // Страницата, която искаме да изтрием

  // Зареждане на списъка с лендинг-страници
  useEffect(() => {
    const fetchLandingPages = async () => {
      const querySnapshot = await getDocs(collection(db, "LandingPages"));
      const pages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLandingPages(pages);
    };
    fetchLandingPages();
  }, []);

  // Функция за показване на модала при натискане на "Изтрий"
  const openDeleteModal = (page) => {
    setPageToDelete(page); // Задава страницата, която ще бъде изтрита
    setIsModalOpen(true); // Отваря модала
  };

  // Функция за изтриване на лендинг-страница
  const handleDelete = async () => {
    if (pageToDelete) {
      await deleteDoc(doc(db, "LandingPages", pageToDelete.id));
      setLandingPages(
        landingPages.filter((page) => page.id !== pageToDelete.id)
      );
      setIsModalOpen(false); // Затваря модала след изтриване
      setPageToDelete(null); // Изчиства избраната страница
    }
  };

  // Функция за превключване на публикуване/непубликуване
  const handleTogglePublish = async (page) => {
    const pageRef = doc(db, "LandingPages", page.id);
    const newPublishedStatus = !page.isPublished;
    await updateDoc(pageRef, { isPublished: newPublishedStatus });

    setLandingPages(
      landingPages.map((p) =>
        p.id === page.id ? { ...p, isPublished: newPublishedStatus } : p
      )
    );
  };

  return (
    <div className="w-full mx-auto mt-4">
      <div className="flex items-center justify-between mt-8">
        <h2 className="text-xl tracking-wider uppercase text-fordBlue">
          Лендинг-страници
        </h2>
        <button
          className="px-4 py-1 bg-white border rounded text-fordBlue hover:text-white border-fordBlue hover:bg-fordBlue"
          onClick={() => {
            navigate("/admin/createlandingpage");
          }}
        >
          Създай нова страница
        </button>
      </div>
      <section className="mt-8">
        {landingPages
          .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds) // Сортира по `createdAt`, от най-новата към най-старата
          .map((page) => (
            <div
              className="flex items-center justify-between px-4 py-2 border-b text-fordBlue odd:bg-gray-50 hover:bg-gray-200"
              key={page.id}
            >
              <p className="text-left">{page.title}</p>
              <div className="flex items-center gap-4 text-white">
                <a
                  href={`/landing/${page.slug}`} // Линк към публичната част на страницата
                  target="_blank" // Отваря се в нов таб
                  rel="noopener noreferrer" // За сигурност
                  className="underline text-fordBlue underline-offset-4"
                >
                  Преглед
                </a>
                <button
                  className="px-4 py-1 rounded-md bg-fordBlue"
                  onClick={() =>
                    navigate(`/admin/edit-landing-page/${page.id}`)
                  } // Пренасочва към страницата за редактиране
                >
                  Редактирай
                </button>
                <button
                  className={`py-1 bg-white border rounded-md ${
                    page.isPublished
                      ? "text-fordRed border-fordRed px-4"
                      : "text-fordBlue border-fordBlue px-6"
                  } border-fordBlue`}
                  onClick={() => handleTogglePublish(page)}
                >
                  {page.isPublished ? "Направи непублична" : "Направи публична"}
                </button>
                <button
                  className="px-4 py-1 rounded-md bg-fordRed"
                  onClick={() => openDeleteModal(page)} // Отваря модала за потвърждение на изтриване
                >
                  Изтрий
                </button>
              </div>
            </div>
          ))}
      </section>

      {/* Модален прозорец за потвърждение на изтриване */}
      {isModalOpen && (
        <Modal handleClose={() => setIsModalOpen(false)}>
          <p className="my-4 text-lg text-center">
            Сигурен ли си? Това действие е необратимо!
          </p>
          <div className="flex justify-center gap-4 mb-4">
            <button
              className="px-4 py-2 text-white rounded bg-fordRed"
              onClick={handleDelete} // Изтрива страницата при потвърждение
            >
              Изтрий
            </button>
            <button
              className="px-4 py-2 bg-white border rounded text-fordBlue border-fordBlue"
              onClick={() => setIsModalOpen(false)} // Затваря модала без действие
            >
              Отказ
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LandingPageList;
