import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCTZsr_lI5TBk8uVj25qdzNt4FCpMm_c2Y",
  authDomain: "sale-ford-ba315.firebaseapp.com",
  projectId: "sale-ford-ba315",
  storageBucket: "sale-ford-ba315.appspot.com",
  messagingSenderId: "292645813624",
  appId: "1:292645813624:web:78646baa3513ba32b49c8d",
  measurementId: "G-9407SBVPX0",
};

initializeApp(firebaseConfig);

const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const analytics = getAnalytics();

export { db, auth, storage, analytics };
