function Aftersales2024() {
  return (
    <div>
      <img
        src="/images/aftersales2024/motopfohe_aftersales_2024.jpg"
        alt=""
        className="hidden mt-1 lg:block"
      />
      <img
        src="/images/aftersales2024/768_1200.jpg"
        alt=""
        className="mt-1 lg:hidden"
      />
      <p className="p-8 text-xl text-center lg:py-32 lg:px-72 text-fordBlue">
        Ние разбираме колко важно е да се чувствате сигурни, особено по време на
        eсенния и зимен сезон. Затова сме тук, за да ви предложим спокойствие и
        увереност по време на път. От сега до 31 декември 2024 г., всеки от
        нашите оторизирани сервизи на Мото-Пфое е подготвен да се погрижи за
        вашата кола, предоставяйки ви най-висок стандарт на обслужване.
      </p>
      <section className="grid grid-cols-2 gap-4 px-4 text-lg lg:grid-cols-4 text-fordLight">
        <div className="text-center transition ease-in-out shadow-md hover:scale-105 ">
          <img src="/images/aftersales2024/focus-lights.jpg" alt="" />
          <p className="m-4">Безплатна проверка и реглаж на светлините</p>
        </div>
        <div className="text-center transition ease-in-out shadow-md hover:scale-105">
          <img src="/images/aftersales2024/ford-check.jpg" alt="" />
          <p className="m-4">
            Безплатна проверка техническото състояние на автомобила по утвърдена
            от Ford чек-лист
          </p>
        </div>
        <div className="text-center transition ease-in-out shadow-md hover:scale-105">
          <img src="/images/aftersales2024/ford-mechanic.jpg" alt="" />
          <p className="m-4">Безплатна проверка гъстотата на антифриза</p>
        </div>
        <div className="text-center transition ease-in-out shadow-md hover:scale-105">
          <img src="/images/aftersales2024/ranger-tyres.jpg" alt="" />
          <p className="m-4">
            Зимни гуми на преференциални цени – само от наличните на склад
          </p>
        </div>
      </section>
      <section className="mx-auto mt-24 lg:w-1/2">
        <p className="text-lg text-center text-fordLight">
          А ако е необходима подмяна на някои важни за сигурността на автомобила
          консумативи:
        </p>
      </section>
      <section className="grid grid-cols-1 mx-auto mt-4 lg:grid-cols-2 lg:w-1/2">
        <div className="p-8 text-center text-white bg-fordLight">
          <p className="my-4 text-4xl">-15%</p>
          <p className="text-xl font-bold">
            на труда и частите
            <br />
            при подмяна на:
          </p>
        </div>
        <div className="flex flex-col items-center justify-center p-8 bg-gray-100">
          <ul className="list-disc list-inside text-fordBlue">
            <li>акумулаторни батерии</li>
            <li>крушки (включително ксенонови)</li>
            <li>антифриз</li>
          </ul>
        </div>
      </section>
      <section className="mx-auto mt-16 lg:w-1/2">
        <p className="text-lg text-center text-fordLight">
          Можете да се възползвате също и от:
        </p>
      </section>
      <section className="grid grid-cols-1 mx-auto mt-4 lg:grid-cols-2 lg:w-1/2">
        <div className="p-8 text-center text-white bg-fordLight">
          <p className="my-4 text-4xl">-15%</p>
          <p className="text-xl font-bold">от цената на</p>
        </div>
        <div className="flex flex-col items-center justify-center p-8 bg-gray-100">
          <p>всички аксесоари Ford и техния монтаж</p>
        </div>
      </section>
      <div className="flex items-center justify-center mt-24">
        <button className="px-24 py-2 text-white rounded-full bg-fordBlue">
          <a
            href="https://ford.bg/dealerships"
            target="_blank"
            rel="noopener noreferrer"
          >
            Свържете се с нас
          </a>
        </button>
      </div>
      <section className="p-4 mt-16 text-sm font-light text-gray-700">
        Кампанията е валидна за периода от 11.11.2024 до 31.12.2023 г. и е
        предназначена за автомобили Ford над 36 месеца от датата на
        първоначалната регистрация. Посочените отстъпки не могат да се
        комбинират с други действащи отстъпки и промоции.
      </section>
    </div>
  );
}

export default Aftersales2024;
