import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config";
import emailjs from "@emailjs/browser";

export const GetInTouch = ({ model, copy }) => {
  const getInTouchForm = useForm();
  const { register, handleSubmit, formState } = getInTouchForm;
  const { errors } = formState;
  const navigate = useNavigate();
  const collectionName = model + "GetInTouch";

  const sendGetinTouch = async (data) => {
    await addDoc(collection(db, collectionName), {
      name: data.name,
      email: data.email,
      phone: data.phone,
      dealership: data.dealership,
      text: data.text,
      date: Timestamp.fromDate(new Date()),
      model: model,
    });
    emailjs.sendForm(
      "service_3ydm7sf",
      "template_5gyby29",
      "#theForm",
      "YzmqGpemdgsw4KxkD"
      // )
      // .then(
      //   (result) => {
      //     console.log(result.text);
      //   },
      //   (error) => {
      //     console.log(error.text);
      //   }
    );
    navigate("/thank-you-offer");
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(sendGetinTouch)}
        id="theForm"
        className="px-4 py-16 lg:px-48"
        noValidate
      >
        <h2 className="text-xl text-center uppercase text-fordBlue">
          Свържи се с търговец
        </h2>
        <div className="mt-8">
          <label htmlFor="name" className="text-gray-500">
            Име *
          </label>
          <input
            type="text"
            id="name"
            name="name"
            {...register("name", {
              required: {
                value: true,
                message: "Моля, въведете Вашето име",
              },
            })}
          />
          <p className="text-red-500">{errors.name?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="email" className="text-gray-500">
            Имейл *
          </label>
          <input
            type="email"
            placeholder="email@email.com"
            id="email"
            name="email"
            {...register("email", {
              required: {
                value: true,
                message: "Моля, въведете имейл-адрес",
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Невалиден имейл-адрес",
              },
            })}
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="phone" className="text-gray-500">
            Телефон *
          </label>
          <input
            type="text"
            placeholder="+359 87 654321"
            id="phone"
            name="phone"
            {...register("phone", {
              required: {
                value: true,
                message: "Моля, въведете телефонен номер",
              },
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                message: "Невалиден телефонен номер",
              },
            })}
          />
          <p className="text-red-500">{errors.phone?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="dealership" className="text-gray-500">
            Предпочитано представителство *
          </label>
          <select
            name="dealership"
            id="dealership"
            required
            {...register("dealership", {
              required: {
                value: true,
                message: "Моля, изберете предпочитано представителство",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="liulin2@motopfohe.bg">София Люлин</option>
            <option value="mladost@motopfohe.bg">София Младост</option>
            <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
            <option value="burgas@motopfohe.bg">Бургас</option>
            <option value="varna@motopfohe.bg">Варна</option>
            <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
            <option value="gabrovo@motopfohe.bg">Габрово</option>
            <option value="dobrich@motopfohe.bg">Добрич</option>
            <option value="montana@motopfohe.bg">Монтана</option>
            <option value="pleven@motopfohe.bg">Плевен</option>
            <option value="plovdiv@motopfohe.bg">Пловдив</option>
            <option value="russe@motopfohe.bg">Русе</option>
            <option value="starazagora@motopfohe.bg">Стара Загора</option>
            <option value="smolyan@motopfohe.bg">Смолян</option>
            <option value="forsh@motopfohe.bg">Шумен</option>
          </select>
          <p className="text-red-500">{errors.dealership?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="zapitvane" className="text-gray-500">
            Запитване *
          </label>
          <textarea
            name="text"
            id="text"
            rows="10"
            className="w-full border border-gray-300 rounded-lg"
            {...register("text", {
              required: {
                value: true,
                message: "Моля, въведете Вашето запитване",
              },
            })}
          ></textarea>
          <p className="text-red-500">{errors.text?.message}</p>
        </div>
        <input
          type="hidden"
          id={model}
          name={model}
          value={model}
          {...register("model")}
        ></input>
        <input
          type="hidden"
          id={copy}
          name={copy}
          value={copy}
          {...register("copy")}
        ></input>
        <button
          type="submit"
          className="px-16 py-2 mx-auto mt-8 text-white border rounded-full bg-fordBlue"
        >
          Изпрати
        </button>
      </form>
    </div>
  );
};
