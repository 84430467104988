import { Link, NavLink, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Suspense, useEffect, useState } from "react";

const hotlist = [
  "VEH170811",
  "VEH170701",
  "VEH164901",
  "VEH166218",
  "VEH174985",
  "VEH170478",
  "VEH170479",
  "VEH170480",
  "VEH170481",
  "VEH170476",
  "VEH170474",
  "VEH170475",
  "VEH171461",
  "VEH171462",
  "VEH171455",
  "VEH171457",
  "VEH170482",
  "VEH170483",
  "VEH170747",
  "VEH170746",
  "VEH173773",
  "VEH173770",
  "VEH173771",
  "VEH173772",
  "VEH173816",
  "VEH173195",
  "VEH172207",
  "VEH170332",
  "VEH169776",
  "VEH169773",
  "VEH164464",
  "VEH166233",
  "VEH165135",
  "VEH169462",
  "VEH169463",
  "VEH169464",
];

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

// Специални ID-та на автомобилите
const specialIds = new Set([
  "VEH172205",
  "VEH172207",
  "VEH173195",
  "VEH173813",
  "VEH173816",
  "VEH173770",
  "VEH173771",
  "VEH173772",
  "VEH173773",
  "VEH172211",
]);

// Функция за конвертиране на кирилица в латиница
function convertCyrillicToLatin(text) {
  text = text.normalize("NFD"); // Нормализиране на текста
  return text
    .replaceAll("й", "i")
    .replaceAll("Й", "I")
    .replaceAll("ц", "ts")
    .replaceAll("Ц", "Ts")
    .replaceAll("у", "u")
    .replaceAll("У", "U")
    .replaceAll("к", "k")
    .replaceAll("К", "K")
    .replaceAll("е", "e")
    .replaceAll("Е", "E")
    .replaceAll("н", "n")
    .replaceAll("Н", "N")
    .replaceAll("г", "g")
    .replaceAll("Г", "G")
    .replaceAll("ш", "sh")
    .replaceAll("Ш", "Sh")
    .replaceAll("щ", "sht")
    .replaceAll("Щ", "Sht")
    .replaceAll("з", "z")
    .replaceAll("З", "Z")
    .replaceAll("х", "h")
    .replaceAll("Х", "H")
    .replaceAll("ъ", "a")
    .replaceAll("Ъ", "A")
    .replaceAll("ф", "f")
    .replaceAll("Ф", "F")
    .replaceAll("в", "v")
    .replaceAll("В", "V")
    .replaceAll("а", "a")
    .replaceAll("А", "A")
    .replaceAll("п", "p")
    .replaceAll("П", "P")
    .replaceAll("р", "r")
    .replaceAll("Р", "R")
    .replaceAll("о", "o")
    .replaceAll("О", "O")
    .replaceAll("т", "t")
    .replaceAll("Т", "T")
    .replaceAll("л", "l")
    .replaceAll("Л", "L")
    .replaceAll("й", "i")
    .replaceAll("Й", "I") // 'й' (Cyrillic Small Letter I with Breve)
    .replaceAll("д", "d")
    .replaceAll("Д", "D") // 'д' (Cyrillic Small Letter D)
    .replaceAll("\u0434", "d")
    .replaceAll("\u0414", "D") // 'д' (Cyrillic Small Letter D)
    .replaceAll("\u0442", "t")
    .replaceAll("\u0422", "T") // 'т' (Cyrillic Small Letter Te)
    .replaceAll("\u0442\u0301", "t") // 'т́' (Cyrillic Small Letter Te with Acute)
    .replaceAll("\u0442\u0327", "t") // 'т̧' (Cyrillic Small Letter Te with Dot Above)
    .replaceAll("\u0442\u0307", "t") // 'т̇' (Cyrillic Small Letter Te with Dot Below)
    .replaceAll("\u0438\u0301", "i") // 'ĭ' (Latin Small Letter I with Breve)
    .replaceAll("\u0438\u0327", "i") // 'i̇' (Latin Small Letter I with Dot Above)
    .replaceAll("\u0456", "i") // 'і' (Cyrillic Small Letter Byelorussian-Ukrainian I)
    .replaceAll("\u0457", "i") // 'ї' (Cyrillic Small Letter Yi)
    .replaceAll("\u0131", "i") // 'ı' (Latin Small Letter Dotless I)
    .replaceAll("\u0458", "i") // 'ј' (Cyrillic Small Letter Je)
    .replaceAll("и", "i")
    .replaceAll("И", "I") // Стандартно 'и'
    .replaceAll("ж", "zh")
    .replaceAll("Ж", "Zh")
    .replaceAll("я", "ya")
    .replaceAll("Я", "Ya")
    .replaceAll("ч", "ch")
    .replaceAll("Ч", "Ch")
    .replaceAll("с", "s")
    .replaceAll("С", "S")
    .replaceAll("м", "m")
    .replaceAll("М", "M")
    .replaceAll("б", "b")
    .replaceAll("Б", "B")
    .replaceAll("ю", "yu")
    .replaceAll("Ю", "Yu")
    .replaceAll("\u0425", "h")
    .replaceAll("\u0425", "H") // 'Х' (Cyrillic Capital Letter HA)
    .replaceAll("\u0438", "i")
    .replaceAll("\u0418", "I"); // 'и' (Cyrillic Small Letter I)
}

// Функция за генериране на URL на изображение
const generateImageURL = (car) => {
  const imageURL = `../images/${convertCyrillicToLatin(car.model)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.body)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.trim)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}-${convertCyrillicToLatin(car.color)
    .toLowerCase()
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\//g, "")}.jpeg`;

  console.log(imageURL); // Добави това
  return imageURL;
};

// Функция за обработка на грешки при зареждане на изображения
const onImageError = (e) => {
  e.target.src = "../images/default-car.jpg";
};

// Основен компонент
export default function CarList({ cars }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showNoCarsMessage, setShowNoCarsMessage] = useState(false);

  // Получаване на параметри от URL
  const getParams = (param) =>
    searchParams.getAll(param).map((p) => p.toUpperCase()) || [];

  const modelsList = getParams("model");
  const fuelsList = getParams("fuel");
  const gearboxesList = getParams("gearbox");
  const vatsList = getParams("vat");

  // Филтриране на автомобили
  const filteredCars = cars.filter(
    (car) =>
      (modelsList.length === 0 ||
        modelsList.includes(car.model.toUpperCase())) &&
      (fuelsList.length === 0 || fuelsList.includes(car.fuel.toUpperCase())) &&
      (gearboxesList.length === 0 ||
        gearboxesList.includes(car.gearbox.toUpperCase())) &&
      (vatsList.length === 0 || vatsList.includes(car.vat.toUpperCase()))
  );

  const sortedCars = [...filteredCars].sort(
    (a, b) => a.new_price - b.new_price
  );

  // Генериране на налични модели
  // const availableModels = [...new Set(cars.map((car) => car.model))];

  // Изчистване на URL параметрите след 5 секунди, ако няма налични автомобили
  useEffect(() => {
    if (filteredCars.length === 0) {
      setShowNoCarsMessage(true);

      const timer = setTimeout(() => {
        setSearchParams({});
      }, 5000); // Изчистване след 5 секунди

      return () => clearTimeout(timer); // Почистете таймера при размонтиране
    } else {
      setShowNoCarsMessage(false);
    }
  }, [filteredCars.length, setSearchParams]);

  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        infiniteLoop={true}
        dynamicHeight={false}
        showStatus={false}
      >
        <NavLink to="/hotlist" className="cursor-pointer">
          <div>
            <img
              src="./images/summer2024/Ford_Summer_Sale_Hero.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </NavLink>
        <NavLink to="/?model=COURIER" className="cursor-pointer">
          <div>
            <img
              src="./images/new-transit-courier/Ford Transit Courier Hero.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </NavLink>
        <NavLink to="/?model=NEW+CUSTOM" className="cursor-pointer">
          <div>
            <img
              src="./images/transit-custom/transit-custom-hero.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </NavLink>
        <NavLink to="/?model=CONNECT" className="cursor-pointer">
          <div>
            <img
              src="./images/transit-connect/transit-connect-slider-homepage.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </NavLink>
        <a href="/?model=KUGA" rel="noreferrer" className="cursor-pointer">
          <div>
            <img
              src="./images/kuga-2024-promo-banner.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </a>
        <NavLink to="/?model=COURIER" className="cursor-pointer">
          <div>
            <img
              src="./images/transit-courier-promo-banner.jpg"
              alt=""
              className="cursor-pointer"
            />
          </div>
        </NavLink>
        <NavLink to="/?model=RANGER">
          <div>
            <img src="./images/raptor-promo-banner.jpg" alt="" />
          </div>
        </NavLink>
      </Carousel>

      <motion.div
        layout
        className="container grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {sortedCars.length > 0 ? (
          sortedCars.map((car) => {
            const imageURL = specialIds.has(car.id)
              ? generateImageURL(car).replace(".jpeg", "-copy.jpeg")
              : generateImageURL(car);

            const discount =
              ((car.old_price - car.new_price) / car.old_price) * 100;

            return (
              <motion.div
                layout
                className="p-2 mt-4 shadow-md item hover:shadow-lg"
                variants={item}
                key={car.id}
                id="the-list"
              >
                <div className="flex items-start justify-between">
                  <div className="min-h-[92px]">
                    <h3 className="mt-2 text-2xl uppercase text-fordBlue">
                      {car.model}
                    </h3>
                    <p className="text-sm text-gray-500">Оферта {car.id}</p>
                  </div>
                  {discount !== 0 && (
                    <div
                      className={`flex flex-col px-4 py-2 text-center rounded-sm min-w-[180px] ${
                        hotlist.includes(car.id) ? "bg-fordRed" : "bg-fordLight"
                      }`}
                    >
                      <p className="text-sm tracking-widest text-white uppercase">
                        {hotlist.includes(car.id)
                          ? "Ексклузивна оферта"
                          : "Специална оферта"}
                      </p>
                      <p className="text-sm tracking-widest text-center text-white uppercase ">
                        -
                        {new Intl.NumberFormat("bg", {
                          maximumFractionDigits: 0,
                        }).format(discount)}
                        %
                      </p>
                    </div>
                  )}
                </div>
                <Suspense fallback={<div>Зареждане...</div>}>
                  <img src={imageURL} alt="" onError={onImageError} />
                </Suspense>
                <div className="flex justify-between">
                  <p className="text-gray-500">Гориво</p>
                  <p className="text-fordBlue">{car.fuel}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Скоростна кутия</p>
                  <p className="text-fordBlue">{car.gearbox}</p>
                </div>
                <p className="p-4 mt-4 text-xl text-center bg-gray-50">
                  Цена:{" "}
                  {new Intl.NumberFormat("bg", {
                    minimumFractionDigits: 0,
                  }).format(car.new_price)}{" "}
                  <span className="text-sm">лв. с ДДС</span>
                </p>
                <div className="mx-auto text-center">
                  <Link to={car.id}>
                    <button className="mt-4 mb-2 border btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                      Виж още
                    </button>
                  </Link>
                </div>
              </motion.div>
            );
          })
        ) : (
          <div className="py-8 mt-16 text-center border rounded-lg text-fordBlue col-span-full">
            <h2 className="text-2xl">
              В момента няма налични автомобили {modelsList}
            </h2>
          </div>
        )}
      </motion.div>
    </>
  );
}
