import TextInput from "./TextInput";

const Block = ({ block, index, updateBlock, removeBlock }) => {
  return (
    <div className="p-4 mt-4 rounded-sm block-item bg-gray-50">
      <input
        type="text"
        value={block.title}
        onChange={(e) =>
          updateBlock(index, { ...block, title: e.target.value })
        }
        placeholder="Заглавие"
      />
      <textarea
        value={block.text}
        onChange={(e) => updateBlock(index, { ...block, text: e.target.value })}
        placeholder="Текст"
        rows={6}
        className="w-full mt-4 border-gray-300 rounded-lg placeholder:text-slate-400"
      />
      {/* Ново поле за име на бутона */}
      <TextInput
        label="Име на бутон 1"
        value={block.button1Text}
        onChange={(e) =>
          updateBlock(index, { ...block, button1Text: e.target.value })
        }
        placeholder="Име на бутон 1"
      />

      {/* Ново поле за URL на линка */}
      <TextInput
        label="URL на бутон 1"
        value={block.button1Url}
        onChange={(e) =>
          updateBlock(index, { ...block, button1Url: e.target.value })
        }
        placeholder="URL на бутона"
      />
      {/* Ново поле за име на бутона */}
      <TextInput
        label="Име на бутон 2"
        value={block.button2Text}
        onChange={(e) =>
          updateBlock(index, { ...block, button2Text: e.target.value })
        }
        placeholder="Име на бутон 2"
      />

      {/* Ново поле за URL на линка */}
      <TextInput
        label="URL на бутон 2"
        value={block.button2Url}
        onChange={(e) =>
          updateBlock(index, { ...block, button2Url: e.target.value })
        }
        placeholder="URL на бутона"
      />
      <input
        type="text"
        value={block.imageUrl}
        onChange={(e) =>
          updateBlock(index, { ...block, imageUrl: e.target.value })
        }
        placeholder="URL на изображението"
      />
      <button
        onClick={() => removeBlock(index)}
        className="px-4 py-2 mt-4 bg-white border rounded text-fordRed hover:text-white border-fordRed hover:bg-fordRed"
      >
        Изтрий блок
      </button>
    </div>
  );
};

export default Block;
