import { useState } from "react";
import { db } from "../firebase/config";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import CSVReader from "react-csv-reader";

function UploadCSV() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const batch = writeBatch(db);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [allDone, setAllDone] = useState(false);

  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };
  function iterate_data(sdata) {
    setData(sdata);
    console.log(sdata);
  }
  // function iterate_data(sdata, fileInfo, originalFile) {
  //   setData(sdata);
  //   console.log(sdata);
  // }

  const reload = () => {
    window.location.reload(false);
  };

  async function import_into_firebase() {
    setLoading(true);
    try {
      const docRef = collection(db, "cars");

      // Изтриване на съществуващите документи
      const querySnapshot = await getDocs(docRef);
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      // Добавяне на новите документи
      const addPromises = data.map((item) =>
        setDoc(doc(db, "cars", item.id), item)
      );
      await Promise.all(addPromises); // Изчакване на всички setDoc операции

      setLoading(false);
      setAllDone(true);
      setTimeout(reload, 10000);
      return docRef;
    } catch (error) {
      console.log(error);
      setLoading(false); // Връщане към false, ако има грешка
    }
  }

  return (
    <>
      <h1 className="mt-12 ml-12 text-3xl">
        Импорт на csv-файл за обновяване на списъка с автомобили
      </h1>
      <div className="p-12 mt-8 bg-gray-100 rounded-md">
        <div className="flex justify-start items-top px-80">
          <input
            type="checkbox"
            name="4"
            id="4"
            onChange={() => setCheck4(!check4)}
          />
          <label htmlFor="4" className="ml-4 -mt-1">
            Моля, уверете се, че файлът е във формат csv с кодиране на символите
            UTF-8
          </label>
        </div>
        <div className="flex justify-start mt-2 items-top px-80">
          <input
            type="checkbox"
            name="1"
            id="1"
            onChange={() => setCheck1(!check1)}
          />
          <label htmlFor="1" className="ml-4 -mt-1 text-left">
            Моля, уверете се, че имената на колоните във файла са Id, model,
            body, color, engine, fuel, gearbox, gears, old_price, new_price,
            base, load, trim, vat, drivetrain
          </label>
        </div>
        <div className="flex justify-start mt-2 items-top px-80">
          <input
            type="checkbox"
            name="2"
            id="2"
            className="text-left"
            onChange={() => setCheck2(!check2)}
          />
          <label htmlFor="2" className="ml-4 -mt-1 text-left">
            Моля, уверете се, че всички колони (с изключение на base и load) не
            съдържат празни клетки
          </label>
        </div>
        <div className="flex justify-start mt-2 items-top px-80">
          <input
            type="checkbox"
            name="3"
            id="3"
            className="text-left"
            onChange={() => setCheck3(!check3)}
          />
          <label htmlFor="3" className="ml-4 -mt-1 text-left">
            Моля, уверете се, че стойностите в колоните old_price и new_price са
            форматирани като число без разделител за хилядите
          </label>
        </div>
        <p className="mt-8">
          За удобство може да изтеглите{" "}
          <a
            href="https://firebasestorage.googleapis.com/v0/b/sale-ford-ba315.appspot.com/o/Template.csv?alt=media&token=9060ec11-c8d6-494b-b185-e2aa704ccd8b&_gl=1*kbxuan*_ga*NDQ3OTI2NTQ4LjE2OTY0MjAwMDQ.*_ga_CW55HF8NVT*MTY5OTExNTA3Ni42OS4wLjE2OTkxMTUwNzYuNjAuMC4w"
            className="text-fordLight"
          >
            този шаблон
          </a>{" "}
          и да попълните клетките в него.
          <br />
          Не забравяйте също да изтриете примерното съдържание!
        </p>
      </div>
      <div className="p-12 mt-8 border rounded-md border-fordBlue">
        <CSVReader
          cssClass={"uploader-input"}
          onFileLoaded={iterate_data}
          parserOptions={papaparseOptions}
        />
      </div>

      {check1 && check2 && check3 && check4 && (
        <button
          onClick={() => import_into_firebase()}
          className="px-12 py-4 mt-8 text-white border rounded-full bg-fordBlue hover:bg-white hover:text-fordBlue border-fordBlue"
        >
          {loading ? "Обработка..." : "Импортиране"}
        </button>
      )}
      {allDone && <p className="mt-4 text-fordBlue">Списъкът е обновен!</p>}
      {/* <div className="p-12 mt-12 ml-12 bg-yellow-300"> */}
      {/* {JSON.stringify(data)} */}

      {/* <table className="p-4 mt-4 ml-12 text-black">
          <tbody>
            {data
              ? data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.city}</td>
                    <td className="ml-2">{item.state}</td>
                    <td className="ml-2">{item.country}</td>
                  </tr>
                ))
              : ""}
          </tbody>
        </table> */}
      {/* </div> */}
    </>
  );
}

export default UploadCSV;
