export default function ThankYouTestdrive() {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen mx-auto bg-[url('../public/images/Kuga-ThankYou-Background.jpg')] bg-center bg-cover">
      {/* Overlay маска */}
      <div className="absolute inset-0 z-0 bg-black opacity-70"></div>

      {/* Съдържание, което се показва над маската */}
      <div className="relative z-10">
        <h1 className="text-4xl text-center text-white">
          Благодарим за запитването!
        </h1>
        <p className="mt-8 text-white">
          Наш представител ще се свърже с Вас възможно най-скоро
        </p>
      </div>
    </div>
  );
}
