export default function ThankYouOffer() {
  return (
    <div className="container flex flex-col items-center justify-center min-h-screen mx-auto">
      <h1 className="text-4xl text-fordBlue">Благодарим за запитването!</h1>
      <p className="mt-8">
        Наш представител ще се свърже с Вас възможно най-скоро
      </p>
    </div>
  );
}
