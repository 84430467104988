import Block from "./Block"; // Импортваме съществуващия компонент Block

const BlockManager = ({ blocks, addBlock, updateBlock, removeBlock }) => {
  return (
    <div className="my-4">
      <h3 className="text-fordBlue">Акценти</h3>
      {blocks.map((block, index) => (
        <Block
          key={index}
          block={block}
          index={index}
          updateBlock={updateBlock}
          removeBlock={removeBlock}
        />
      ))}
      <button
        type="button"
        onClick={addBlock}
        className="px-4 py-2 mt-4 text-white rounded bg-fordBlue"
      >
        Добави нов блок
      </button>
    </div>
  );
};

export default BlockManager;
