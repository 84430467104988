import { NavLink, Link, Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";

export default function RootLayoout() {
  const { logout } = useLogout();
  const { user } = useAuthContext();

  return (
    <div className="z-20 mx-auto border-gray-200 shadow-md font-fordLight 2xl:container border-x">
      <div className="border-gray-200 shadow-md">
        <header className="flex justify-end p-2 align-middle bg-gray-50">
          <a
            href="https://ford.bg"
            target="_blank"
            rel="noreferrer"
            className="text-sm"
          >
            Към ford.bg
          </a>
          <a
            href="https://ford.bg/terms-and-privacy-policy-hub"
            target="_blank"
            rel="noreferrer"
            className="ml-4 text-sm"
          >
            Политика за ползване
          </a>
          {user && (
            <Link
              onClick={logout}
              className="mx-2 text-sm tracking-widest cursor-pointer font-fordRegular text-fordLight"
            >
              Изход
            </Link>
          )}
        </header>
        <menu className="justify-between hidden p-8 lg:flex align-content-center">
          <NavLink to="/" className="cursor-pointer">
            <img
              src="https://ford.bg/assets/ford_oval_blue_logo-37c30dcc972d03ba21abd7acfcc8e200e5b9629a52d43546efbb8bef1b6907e7.svg"
              alt=""
              srcSet=""
            />
          </NavLink>
          <div className="flex justify-around p-2">
            {/* <a
              href="https://ford.bg/configurators"
              rel="noreferrer"
              target="_blank"
              className="mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
            >
              Автомобили Ford
            </a> */}
            <NavLink
              to="/"
              className="mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
            >
              Автомобили Ford
            </NavLink>
            <p className="mx-4 text-sm text-gray-200">|</p>
            <a
              href="https://ford.bg/"
              rel="noreferrer"
              target="_blank"
              className="mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
            >
              Как да купите
            </a>
            <p className="mx-4 text-sm text-gray-200">|</p>
            <a
              href="https://ford.bg/"
              rel="noreferrer"
              target="_blank"
              className="mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
            >
              За собственици на Ford
            </a>
            <p className="mx-4 text-sm text-gray-200">|</p>
            <a
              href="https://ford.bg/"
              rel="noreferrer"
              target="_blank"
              className="mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
            >
              За Ford
            </a>
          </div>
          <a
            href="https://ford.bg/testdrives"
            target="_blank"
            rel="noreferrer"
            className="py-2 mx-2 text-sm tracking-widest uppercase text-fordBlue font-fordMedium"
          >
            Заяви тестдрайв
          </a>
        </menu>
      </div>
      <Outlet />
      <footer className="justify-between p-8 mt-8 text-gray-400 lg:flex flex-column bg-fordBlue">
        <div>
          <p className="text-2xl tracking-wide text-white">Автомобили</p>
          <a
            href="https://ford.bg/configurators"
            target="_blank"
            rel="noreferrer"
            className="block mt-2 text-lg tracking-wide"
          >
            Леки автомобили
          </a>
          <a
            href="https://www.ford.bg/configurators?filter%5Bcategory%5D=personal-vehicles&filter%5Bvehicle_type_ids%5D%5B%5D=2"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Хибриди и електрически
          </a>
          <a
            href="https://ford.bg/configurators?filter%5Bcategory%5D=personal-vehicles&filter%5Bvehicle_type_ids%5D%5B%5D=1"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            SUV автомобили
          </a>
          <a
            href="https://sale.ford.bg/"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Автомобили на склад
          </a>
          <a
            href="https://ford.bg/brochures_and_pricelists"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Брошури и ценови листи
          </a>
          <a
            href="https://ford.bg/testdrives"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Заявете пробно шофиране
          </a>
        </div>
        <div className="mt-4 lg:mt-0">
          <p className="text-2xl tracking-wide text-white">За бизнеса</p>
          <a
            href="https://ford.bg/configurators?filter%5Bcategory%5D=commercial-vehicles&filter%5Bvehicle_type_ids%5D%5B%5D=5"
            target="_blank"
            rel="noreferrer"
            className="block mt-2 text-lg tracking-wide"
          >
            Лекотоварни автомобили
          </a>
          <a
            href="https://ford.bg/configurators?filter%5Bcategory%5D=commercial-vehicles&filter%5Bvehicle_type_ids%5D%5B%5D=8"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Пикапи
          </a>
          <a
            href="https://ford.bg/configurators?filter%5Bcategory%5D=commercial-vehicles&filter%5Bvehicle_type_ids%5D%5B%5D=9"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Шаси кабини
          </a>
          <a
            href="https://ford.bg/corporate/fleet"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Корпоративни клиенти
          </a>
        </div>
        <div className="mt-4 lg:mt-0">
          <p className="text-2xl tracking-wide text-white cursor-pointer">
            За собственици на Ford
          </p>
          <a
            href="https://ford.bg/service-and-support/service"
            target="_blank"
            rel="noreferrer"
            className="block mt-2 text-lg tracking-wide"
          >
            Сервизно обслужване
          </a>
          <a
            href="https://ford.bg/owners/spare-parts"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Резервни части
          </a>
          <a
            href="https://ford.bg/service-and-support/resources-and-support/warranties"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Гаранция
          </a>
          <a
            href="https://ford.bg/service-and-support/ford-etis"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            FORD ETIS
          </a>
          <a
            href="https://ford.bg/reach"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            REACH
          </a>
        </div>
        <div className="mt-4 lg:mt-0">
          <p className="text-2xl tracking-wide text-white">Светът на Ford</p>
          <a
            href="https://ford.bg/articles"
            target="_blank"
            rel="noreferrer"
            className="block mt-2 text-lg tracking-wide"
          >
            Новини
          </a>
          <a
            href="https://ford.bg/about_ford/the-ford"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            За Ford
          </a>
          <a
            href="https://ford.bg/dealerships"
            target="_blank"
            rel="noreferrer"
            className="block text-lg tracking-wide"
          >
            Свържете се с нас
          </a>
        </div>
      </footer>
      <div className="flex items-center justify-between p-4 flex-column lg:flex-row">
        <div className="flex flex-column lg:flex-row">
          <p className="mx-2 text-sm tracking-wide">
            © 2023 Ford Motor Company Ltd
          </p>
          <a
            href="https://ford.bg/accessibility"
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-sm tracking-wide"
          >
            Достъпност
          </a>
          <a
            href="https://ford.bg/terms-and-privacy-policy-hub"
            target="_blank"
            rel="noreferrer"
            className="mx-2 text-sm tracking-wide"
          >
            Политика за ползване на сайта
          </a>
        </div>
        <img
          src="https://ford.bg/assets/logo-ford-8d046b541a6ce5e50de2ec73aa4f50d35d9201aeeee81b0b1ffec07ac3e5b0f6.svg"
          alt=""
          srcSet=""
          className="mt-4 lg:mt-0"
        />
      </div>
    </div>
  );
}
