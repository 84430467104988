import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import "./App.css";

import RootLayout from "./layouts/RootLayout";

import Home, { carsLoader } from "./pages/Home";
import Terms from "./pages/Terms";
import NotFound from "./pages/NotFound";
import CarDetails, { carDetailsLoader } from "./pages/CarDetails";
import { usersLoader } from "./pages/OfferRequests";
import ThankYouOffer from "./pages/ThankYouOffer";
import ThankYouTestdrive from "./pages/ThankYouTestdrive";
import Login from "./pages/Login";
// import Upload from "./pages/Upload";
import OfferRequests from "./pages/OfferRequests";
import TestdriveRequests, {
  usersTestdriveLoader,
} from "./pages/TestdriveRequests";
import AdminLayout from "./layouts/AdminLayout";
import NewElectricExplorer from "./pages/NewElectricExplorer";
import RangerRaptor from "./pages/RangerRaptor";
import Bronco from "./pages/Bronco";
import Puma from "./pages/Puma";
import Testdrive from "./pages/Testdrive";
import { Stats } from "./pages/Stats";
import MachE from "./pages/MachE";
import PumaOfferRequests, {
  pumaProposalLoader,
} from "./pages/PumaOfferRequests";
import PumaGetInTouchRequests, {
  pumaGetInTouchLoader,
} from "./pages/GetInTouchPumaRequests";
import RangerRequests, { rangerLoader } from "./pages/RangerRequests";
import TestdrivePageRequests, {
  testdrivePageLoader,
} from "./pages/TestdrivePageRequests";
import MachEOfferRequests, {
  machEProposalLoader,
} from "./pages/MachEOfferRequests";
import MachEGetInTouchRequests, {
  machEGetInTouchLoader,
} from "./pages/MachEGetInTouchRequests";
import UploadCSV from "./pages/UploadCSV";
import FirebaseQueryTest from "./pages/FirebaseQueryTest";
import TransitCourier from "./pages/TransitCourier";
import TransitCourierGetInTouchRequests, {
  transitCourierGetInTouchLoader,
} from "./pages/GetInTouchTransitCourierRequests";
import Kuga from "./pages/Kuga";
import KugaGetInTouchRequests, {
  kugaGetInTouchLoader,
} from "./pages/KugaGetInTouchRequests";
import KugaOfferRequests, {
  kugaProposalLoader,
} from "./pages/KugaOfferRequests";
import PumaKuga from "./pages/Puma-Kuga";
import PumaKugaRequests, { pumaKugaLoader } from "./pages/PumaKugaRequests";
import TransitConnect from "./pages/TransitConnect";
import TransitConnectRequests, {
  transitConnectLoader,
} from "./pages/TransitConnectRequests";
import NewTransitCourier from "./pages/NewTransitCourier";

//----------------------------------------------------------------------
// TEST NAVISION
//----------------------------------------------------------------------
import TestNavision, { testNavisionLoader } from "./pages/TestNavision";
import TransitCustom from "./pages/TransitCustom";
import TransitCustomRequests, {
  transitCustomLoader,
} from "./pages/TransitCustomRequests";
import BroncoTestdriveRequests, {
  broncoTestdriveLoader,
} from "./pages/BroncoTestdriveRequests";
import Summer2024 from "./pages/Summer2024";
import Summer2024Requests, {
  summer2024Loader,
} from "./pages/Summer2024Requests";
import Hotlist from "./pages/Hotlist";
import MustangTestDrive from "./pages/MustangTestDrive";
import MustangTestdriveRequests, {
  MustangTestdriveLoader,
} from "./pages/MustangTestdriveRequests";
import CreateLandingPage from "./pages/CreateLandingpage";
import DynamicPage from "./pages/DynamicPage";
import LandingPageList from "./pages/Landingpages";
import EditLandingPage from "./pages/EditLandingPage";
import ImageManager from "./components/ImageManager";
import Aftersales2024 from "./pages/Aftersales2024";
import Puma2024 from "./pages/Puma2024";
import Puma2024GetInTouchRequests, {
  puma2024GetInTouchLoader,
} from "./pages/Puma2024requests";
//----------------------------------------------------------------------

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />} errorElement={<NotFound />}>
        <Route index element={<Home />} loader={carsLoader} />
        <Route
          path="test-navision"
          element={<TestNavision />}
          loader={testNavisionLoader}
        />
        <Route path="terms" element={<Terms />} />
        <Route path="hotlist" element={<Hotlist />} loader={carsLoader} />
        <Route path="landing/:slug" element={<DynamicPage />} />
        <Route path="landing/aftersales2024" element={<Aftersales2024 />} />
        <Route path="landing/puma2024" element={<Puma2024 />} />
        <Route path=":id" element={<CarDetails />} loader={carDetailsLoader} />
        <Route path="admin" element={<AdminLayout />}>
          {/* <Route path="upload" element={<Upload />} loader={carsLoader} /> */}
          <Route path="upload" element={<UploadCSV />} />
          <Route path="createlandingpage" element={<CreateLandingPage />} />
          <Route path="images" element={<ImageManager />} />
          <Route path="edit-landing-page/:id" element={<EditLandingPage />} />
          <Route path="landingpages" element={<LandingPageList />} />
          <Route path="stats" element={<Stats />}>
            <Route
              path="offer-requests"
              element={<OfferRequests />}
              loader={usersLoader}
            />
            <Route
              path="testdrive-requests"
              element={<TestdriveRequests />}
              loader={usersTestdriveLoader}
            />
            <Route
              path="puma-kuga-requests"
              element={<PumaKugaRequests />}
              loader={pumaKugaLoader}
            />
            <Route
              path="transit-connect-requests"
              element={<TransitConnectRequests />}
              loader={transitConnectLoader}
            />
            <Route
              path="bronco-testdrive-requests"
              element={<BroncoTestdriveRequests />}
              loader={broncoTestdriveLoader}
            />
            <Route
              path="transit-custom-requests"
              element={<TransitCustomRequests />}
              loader={transitCustomLoader}
            />
            <Route
              path="puma-offer-requests"
              element={<PumaOfferRequests />}
              loader={pumaProposalLoader}
            />
            <Route
              path="puma-getintouch-requests"
              element={<PumaGetInTouchRequests />}
              loader={pumaGetInTouchLoader}
            />
            <Route
              path="ranger-requests"
              element={<RangerRequests />}
              loader={rangerLoader}
            />
            <Route
              path="testdrive-page-requests"
              element={<TestdrivePageRequests />}
              loader={testdrivePageLoader}
            />
            <Route
              path="mach-e-offer-requests"
              element={<MachEOfferRequests />}
              loader={machEProposalLoader}
            />
            <Route
              path="mach-e-getintouch-requests"
              element={<MachEGetInTouchRequests />}
              loader={machEGetInTouchLoader}
            />
            <Route
              path="transit-courier-getintouch-requests"
              element={<TransitCourierGetInTouchRequests />}
              loader={transitCourierGetInTouchLoader}
            />
            <Route
              path="kuga-proposal-requests"
              element={<KugaOfferRequests />}
              loader={kugaProposalLoader}
            />
            <Route
              path="kuga-getintouch-requests"
              element={<KugaGetInTouchRequests />}
              loader={kugaGetInTouchLoader}
            />
            <Route
              path="summer2024-requests"
              element={<Summer2024Requests />}
              loader={summer2024Loader}
            />
            <Route
              path="mustang-testdrive-requests"
              element={<MustangTestdriveRequests />}
              loader={MustangTestdriveLoader}
            />
            <Route
              path="puma2024-requests"
              element={<Puma2024GetInTouchRequests />}
              loader={puma2024GetInTouchLoader}
            />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="testdrive" element={<Testdrive />} />
        <Route path="thank-you-offer" element={<ThankYouOffer />} />
        <Route path="thank-you-testdrive" element={<ThankYouTestdrive />} />
        <Route path="new-electric-explorer" element={<NewElectricExplorer />} />
        <Route path="ranger-raptor" element={<RangerRaptor />} />
        <Route path="bronco" element={<Bronco />} />
        {'/* <Route path="mach-e" element={<MachE />} /> */'}
        <Route path="puma" element={<Puma />} />
        {/* <Route path="puma-kuga" element={<PumaKuga />} /> */}
        <Route path="login" element={<Login />} />
        <Route path="firebasequerytest" element={<FirebaseQueryTest />} />
        <Route path="transit-courier" element={<TransitCourier />} />
        <Route path="new-transit-courier" element={<NewTransitCourier />} />
        <Route path="transit-connect" element={<TransitConnect />} />
        <Route path="transit-custom" element={<TransitCustom />} />
        <Route path="summer-offers" element={<Summer2024 />} />
        <Route path="mustangtestdrive" element={<MustangTestDrive />} />
        {'/*<Route path="kuga" element={<Kuga />} />*/'}
      </Route>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
