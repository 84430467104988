import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import { useLoaderData } from "react-router-dom";
import ExportTableAsCSV from "../components/ExportTableAsCSV";

function MustangTestdriveRequests() {
  const usersList = [];
  const result = useLoaderData(MustangTestdriveLoader);

  result.forEach((doc) => {
    usersList.push({ id: doc.id, ...doc.data() });
    usersList.sort((a, b) => b.date - a.date);
  });

  return (
    <div className="container py-8 mx-auto">
      <ExportTableAsCSV
        tableData={document.querySelector(".mustangTestdriveTable")}
        fileName="Mustang_Testdrive_Requests.csv"
      />
      <div className="grid grid-cols-5 gap-0 p-2 text-white bg-fordBlue mustangTestdriveTable">
        <h4 className="col-span-1">Име</h4>
        <h4 className="col-span-1 text-center">Имейл</h4>
        <h4 className="col-span-1 text-center">Телефон</h4>
        <h4 className="text-center">Град</h4>
        <h4 className="text-center ">Дата</h4>
      </div>
      {usersList &&
        usersList.map((user) => (
          <div
            className="grid grid-cols-5 gap-2 p-2 border-b text-fordBlue even:bg-gray-50 hover:bg-gray-200 offerTableRow"
            key={user.id}
          >
            <p className="col-span-1">{user.userName}</p>
            <p className="col-span-1 text-center">{user.email}</p>
            <p className="col-span-1 text-center">{user.phone}</p>
            <p className="text-center ">{user.dealership}</p>
            <p className="text-center ">
              {user.date.toDate().toLocaleDateString("bg-BG")}
            </p>
          </div>
        ))}
    </div>
  );
}

export default MustangTestdriveRequests;

export const MustangTestdriveLoader = async () => {
  const querySnapshot = await getDocs(collection(db, "mustangTestdrive"));
  return querySnapshot;
};
