import { useNavigate } from "react-router-dom";
import LandingPageForm from "../components/LandingPageForm";

const CreateLandingPage = () => {
  const navigate = useNavigate();

  const handlePageCreation = () => {
    navigate("/landingpages"); // След успешно създаване, пренасочва към списъка
  };

  return (
    <div>
      <h2 className="mt-4 mb-4 text-xl tracking-wider uppercase">
        Създай нова лендинг-страница
      </h2>
      <LandingPageForm onSubmit={handlePageCreation} />
    </div>
  );
};

export default CreateLandingPage;
