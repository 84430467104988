import { Timestamp, addDoc, collection } from "firebase/firestore";
// import { useState } from "react";
import { db } from "../firebase/config";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

function Bronco() {
  document.title = "Ford Bronco";
  // const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();

  const broncoForm24 = useForm();
  const { register, handleSubmit, formState } = broncoForm24;
  const { errors } = formState;

  const addUserBronco24 = async (data) => {
    await addDoc(collection(db, "usersBronco24"), {
      name: data.name,
      email: data.email,
      phone: data.phone,
      iskam: data.iskam,
      dealership: data.dealership,
      date: Timestamp.fromDate(new Date()),
    });
    emailjs.sendForm(
      "service_3ydm7sf",
      "template_h8nxclh",
      "#bronco-form",
      "YzmqGpemdgsw4KxkD"
    );
    // .then(
    //   (result) => {
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
    navigate("/thank-you-offer");
  };

  return (
    <div>
      {/* <div className="lg:min-h-[786px] bg-[url('../public/images/bronco/BornWild.png')] bg-center lg:bg-bottom p-0 lg:p-16 lg:p-16 bg-cover lg:bg-auto">
        <h1 className="hidden text-3xl lg:block font-fordMedium text-fordBlue">
          Новият Ford Bronco
        </h1>
        <h2 className="hidden text-2xl lg:block text-fordBlue">
          Емблематичният американски офроудър
        </h2>
        <p className="hidden text-3xl uppercase lg:block font-fordMedium text-fordBlue">
          Идва в България
        </p>
        <button className="px-4 py-2 mt-6 mb-40 ml-8 text-sm tracking-widest text-white rounded-full lg:ml-0 lg:px-12 lg:mt-8 bg-fordBlue font-fordMedium lg:text-md">
          <a href="#form-block">Абонирай се за новини</a>
        </button>
      </div> */}
      <img src="/images/bronco/bronco.jpg" alt="" />
      <h1 className="mt-16 text-3xl leading-normal tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
        ЕМБЛЕМАТИЧНИЯТ АМЕРИКАНСКИ ОФРОУДЪР
        <br />
        <span className="font-fordMedium">ВЕЧЕ В БЪЛГАРИЯ</span>
      </h1>
      <p className="mx-4 mt-8 text-xl tracking-wide text-center lg:mx-64">
        Иконичен дизайн. Здравo изпълнение. Свързаност от следващо поколение.
        Ford Bronco. Най-добрият офроуд 4х4 за търсачите на приключения.
        Наслаждавайте се на истински свободния си дух.
      </p>
      <div className="grid grid-cols-1 mt-8 lg:mx-64 lg:grid-cols-2">
        <div className="flex flex-col items-center justify-center h-16 py-24 text-2xl text-white bg-fordLight">
          <p className="font-fordLight">ПЪТЕН ПРОСВЕТ</p>
          <p className="mt-4 font-fordMedium">до 261 мм</p>
        </div>
        <div className="flex flex-col items-center justify-center h-16 py-24 text-2xl text-white bg-fordBlue">
          <p className="font-fordLight">ДЪЛБОЧИНА НА ГАЗЕНЕ</p>
          <p className="mt-4 font-fordMedium">до 800 мм</p>
        </div>
      </div>
      <p className="mt-16 text-3xl leading-normal tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
        ЗАЯВИ ТЕСТ ДРАЙВ
      </p>
      <form
        className="mx-4 mb-24 lg:mx-64"
        onSubmit={handleSubmit(addUserBronco24)}
        id="bronco-form"
        noValidate
      >
        <label htmlFor="name" className="block mt-8 text-gray-500">
          Име *
        </label>
        <input
          type="name"
          id="name"
          name="name"
          className="w-full px-4 py-3 border border-gray-200 rounded-md"
          {...register("name", {
            required: {
              value: true,
              message: "Моля, въведете Вашето име",
            },
          })}
        />
        <p className="text-red-500">{errors.name?.message}</p>
        <label htmlFor="email" className="block mt-4 text-gray-500">
          Имейл *
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="w-full px-4 py-3 border border-gray-200 rounded-md"
          {...register("email", {
            required: {
              value: true,
              message: "Моля, въведете имейл-адрес",
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Невалиден имейл-адрес",
            },
          })}
        />
        <p className="text-red-500">{errors.email?.message}</p>
        <label htmlFor="phone" className="block mt-4 text-gray-500">
          Телефон *
        </label>
        <input
          type="phonenumber"
          id="phone"
          name="phone"
          className="w-full px-4 py-3 border border-gray-200 rounded-md"
          {...register("phone", {
            required: {
              value: true,
              message: "Моля, въведете телефонен номер",
            },
            pattern: {
              value:
                /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
              message:
                "Невалиден телефонен номер. Моля използвайте формат +359881234567.",
            },
          })}
        />
        <p className="text-red-500">{errors.phone?.message}</p>
        <label htmlFor="filial" className="block mt-4 text-gray-500">
          Филиал *
        </label>
        <select
          name="dealership"
          id="dealership"
          className="w-full px-4 py-3 border border-gray-200 rounded-md"
          {...register("dealership", {
            required: {
              value: true,
              message: "Моля, изберете предпочитано представителство",
            },
          })}
        >
          <option value="" disabled selected>
            Моля, изберете
          </option>
          <option value="liulin2@motopfohe.bg">София Люлин</option>
          <option value="mladost@motopfohe.bg">София Младост</option>
          <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
          <option value="burgas">Бургас</option>
          <option value="varna@motopfohe.bg">Варна</option>
          <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
          <option value="gabrovo@motopfohe.bg">Габрово</option>
          <option value="dobrich@motopfohe.bg">Добрич</option>
          <option value="montana@motopfohe.bg">Монтана</option>
          <option value="pleven@motopfohe.bg">Плевен</option>
          <option value="plovdiv@motopfohe.bg">Пловдив</option>
          <option value="russe@motopfohe.bg">Русе</option>
          <option value="starazagora@motopfohe.bg">Стара Загора</option>
          <option value="smolyan@motopfohe.bg">Смолян</option>
          <option value="forsh@motopfohe.bg">Шумен</option>
        </select>
        <p className="text-red-500">{errors.dealership?.message}</p>
        <div className="flex-row w-full mt-12 text-gray-500">
          <input
            type="checkbox"
            id="suglasen"
            name="suglasen"
            value="suglasen"
            {...register("suglasen", {
              required: {
                value: true,
                message: "Това съгласие е задължително",
              },
            })}
          />
          <label htmlFor="suglasen" className="p-4">
            Съгласен/на съм с политиката за поверителност /задължително поле/
          </label>
          <p className="text-red-500">{errors.suglasen?.message}</p>
        </div>
        <div className="flex-row mt-1 text-gray-500">
          <input
            type="checkbox"
            id="iskam"
            name="iskam"
            {...register("iskam", {
              required: {
                value: false,
                message: "Моля, помислете пак",
              },
            })}
          />
          <label htmlFor="iskam" className="p-4">
            Искам да ме информирате по имейл за продукти и оферти
          </label>
          <p className="text-red-500">{errors.iskam?.message}</p>
        </div>
        <button className="w-full p-4 mt-12 text-lg tracking-wider text-white uppercase rounded-full bg-fordBlue">
          Изпращане
        </button>
      </form>
      {/* <div className="mx-auto my-4 lg:hidden">
        <h1 className="text-3xl text-center font-fordMedium text-fordBlue">
          Новият Ford Bronco
        </h1>
        <h2 className="text-2xl text-center text-fordBlue">
          Емблематичният американски офроудър
        </h2>
        <p className="text-3xl text-center uppercase font-fordMedium text-fordBlue">
          Идва в България
        </p>
      </div>
      <p className="mt-8 text-sm tracking-widest text-center">
        Bronco се очаква да бъде наличен в България в началото на 2023 г.
      </p>
      <p className="text-sm tracking-widest text-center">
        Всички данни за модела ще бъдат обявени своевременно преди пускането му
        на пазара.
      </p>
      <h2 className="mt-16 text-3xl leading-normal tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
        Готови ли сте да дадете воля на <br />
        <span className="font-fordMedium">дивото в себе си?</span>
      </h2>
      <p className="mt-4 text-xl tracking-widest text-center uppercase lg:text-2xl font-fordLight">
        КУЛТОВИЯТ АМЕРИКАНСКИ ОФРОУДЪР Е ГОТОВ ДА ЗАВЛАДЕЕ БЪЛГАРИЯ
      </p>
      <p className="mx-4 mt-8 tracking-widest text-center lg:mx-32">
        Винаги готов за приключения, неопитомен и изключително издръжлив, новият
        Ford Bronco съчетава наследството от своите емблематични предшественици
        с модерни технологии и модерност, гарантирайки на офроуд ентусиастите
        тръпката от свободната езда. Характеризиращ се със солидна конструкция,
        Ford Bronco успешно покри тестовете за екстремна издръжливост The Built
        Wild™, което означава, че шофирането в най-трудния терен не е
        предизвикателство за него. Отличаващ се с най-съвременното задвижване на
        всички колела и усъвършенствано стабилизиращо окачване с висока
        производителност, Ford Bronco доказва, че е неопитомен офроудър като
        Вас!
      </p>
      <h2 className="mt-16 text-3xl tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
        ОТКРИЙТЕ BRONCO
      </h2>
      <div className="flex justify-center max-w-5xl mx-auto mt-8">
        <p
          className="pb-2 mx-2 tracking-widest uppercase cursor-pointer lg:mx-8 tab-link"
          data-tab="1"
          onClick={() => {
            setActiveTab(1);
          }}
        >
          Офроуд
        </p>
        <p
          className="pb-2 mx-2 tracking-widest uppercase cursor-pointer lg:mx-8 tab-link"
          data-tab="2"
          onClick={() => {
            setActiveTab(2);
          }}
        >
          Дизайн
        </p>
        <p
          className="pb-2 mx-2 tracking-widest uppercase cursor-pointer lg:mx-8 tab-link"
          data-tab="3"
          onClick={() => {
            setActiveTab(3);
          }}
        >
          Свързаност
        </p>
      </div>
      {activeTab === 1 && (
        <div className="tab-content bg-[url('../public/images/bronco/tab-1-mobile.jpeg')] lg:bg-[url('../public/images/bronco/tab-1.jpeg')] bg-bottom bg-contian lg:bg-cover h-[700px] mt-8">
          <h4 className="pt-8 text-2xl tracking-widest text-center text-white uppercase lg:text-4xl font-fordLight">
            Истински свободен дух
          </h4>
          <p className="mx-4 mt-2 leading-5 tracking-wider lg;tracking-widest lg:leading-6 text-center text-white lg:mt-8 lg:mx-64">
            Ford Bronco е истински офроудър за всякакви условия и
            най-взискателните настилки. Усъвършенстваната система за управление
            на терена GOAT (Go Over Any Terrain) ви позволява да избирате
            автоматични режими на шофиране, които да отговарят на всяка
            настилка. Мощните офроуд гуми, специалното окачване и подсилената
            конструкция на автомобила гарантират уверено шофиране при
            преодоляване на всяко предизвикателство.
          </p>
        </div>
      )}
      {activeTab === 2 && (
        <div className="tab-content bg-[url('../public/images/bronco/tab-2-mobile.jpeg')] lg:bg-[url('../public/images/bronco/tab-2.jpeg')] bg-top bg-contian lg:bg-cover h-[700px] mt-8">
          <h4 className="pt-8 text-2xl tracking-widest text-center text-white uppercase lg:text-4xl font-fordLight">
            ЛЕГЕНДАРЕН OFF-ROAD ДИЗАЙН
          </h4>
          <p className="mx-4 mt-2 leading-5 tracking-wider lg;tracking-widest lg:leading-6 text-center text-white lg:mt-8 lg:mx-64">
            Ford Bronco със своя силен и строг силует стилистично препраща към
            култовите си предшественици, перфектно съчетавайки стил с модерност.
            Проектиран и за свободноото време и за ежедневието Bronco доставя
            единствено удоволствие. Ford Bronco може да бъде персонализиран в
            зависимост от вашите нужди, благодарение на което можете лесно да го
            пригодите към всяко пътуване. Оборудван с модулни врати и покрив,
            които се демонтират бързо и лесно. Добавянето на практични аксесоари
            като релси на покрива ще улесни отдиха на открито.
          </p>
        </div>
      )}
      {activeTab === 3 && (
        <div className="tab-content bg-[url('../public/images/bronco/tab-3-mobile.jpeg')] lg:bg-[url('../public/images/bronco/tab-3.jpeg')] bg-top bg-contian lg:bg-cover h-[700px] mt-8">
          <h4 className="pt-8 text-2xl tracking-widest text-center text-white uppercase lg:text-4xl font-fordLight">
            ИНТУИТИВНИ ТЕХНОЛОГИИ
          </h4>
          <p className="mx-4 mt-2 leading-5 tracking-wider lg;tracking-widest lg:leading-6 text-center text-white lg:mt-8 lg:mx-64">
            Новият Ford Bronco е оборудван с модерни технологии, които
            осигуряват комфорт при шофиране и изключително изживяване при
            шофиране. Системата за комуникация и развлечение SYNC4 с 12-инчов
            сензорен екран позволява гласово управление на много функции, като
            навигация или телефонни разговори. Отличното качество на звука се
            осигурява от B&O Premium Sound System с 10 високоговорителя.
          </p>
        </div>
      )} */}

      {/* <div className="my-16 lg:my-32" id="form-block">
        <h2 className="text-3xl tracking-widest text-center uppercase lg:mt-32 lg:text-5xl font-fordLight text-fordBlue">
          Заявете своя интерес за BRONCO новини!
        </h2>
        <form
          id="bronco-form"
          className="max-w-2xl mx-4 mt-8 space-y-6 lg:mx-auto"
          onSubmit={handleSubmit(addUserBronco)}
          noValidate
        >
          <div className="mt-4">
            <label htmlFor="name" className="tracking-wider text-gray-400">
              Име
            </label>
            <div>
              <input
                type="text"
                id="name"
                name="name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Моля, въведете Вашето име",
                  },
                })}
              />
              <p className="text-red-500">{errors.name?.message}</p>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="еmail" className="tracking-wider text-gray-400">
              Имейл
            </label>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                {...register("email", {
                  required: {
                    value: true,
                    message: "Моля, въведете имейл-адрес",
                  },
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Невалиден имейл-адрес",
                  },
                })}
              />
              <p className="text-red-500">{errors.email?.message}</p>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="phone" className="tracking-wider text-gray-400">
              Телефон
            </label>
            <div>
              <input
                type="tel"
                id="phone"
                name="phone"
                {...register("phone", {
                  required: {
                    value: true,
                    message: "Моля, въведете телефонен номер",
                  },
                  pattern: {
                    value:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                    message: "Невалиден телефонен номер",
                  },
                })}
              />
              <p className="text-red-500">{errors.phone?.message}</p>
            </div>
          </div>
          <p className="mt-8 text-2xl uppercase">
            Маркетинг комуникация от Ford България
          </p>
          <small className="text-gray-500">
            Мото-Пфое, ще използва вашите данни, за да ви изпраща новини за
            новият Ford Bronco и премиерата му в България. За повече информация
            относно как ползваме предоставените от вас данни, споделянето им и
            вашите права, ви молим да посетите страницата ни{" "}
            <a
              href="https://ford.bg/terms-and-privacy-policy-hub"
              target="_blank"
              rel="noreferrer"
              className="underline text-fordBlue"
            >
              Политика за поверителност
            </a>
            .
          </small>
          <br />
          <p className="mt-4 font-fordMedium">
            Съгласие за ползване на Данните ви за маркетингови цели от Ford и
            Moto-Pfohe
          </p>
          <div className="flex item-center">
            <input
              type="checkbox"
              className="mt-2"
              name="potelefon"
              id="potelefon"
              {...register("potelefon")}
            />
            <label htmlFor="telefon" className="block mt-1 ml-2">
              По телефон
            </label>
          </div>
          <div className="flex item-center">
            <input
              type="checkbox"
              className="mt-2"
              name="posms"
              id="posms"
              {...register("posms")}
            />
            <label htmlFor="sms" className="block mt-1 ml-2">
              Чрез SMS
            </label>
          </div>
          <div className="flex item-center">
            <input
              type="checkbox"
              className="mt-2"
              name="poemail"
              id="poemail"
              {...register("poemail")}
            />
            <label htmlFor="email" className="block mt-1 ml-2">
              По имейл
            </label>
          </div>
          <button className="px-12 py-2 mx-auto mt-4 tracking-widest text-white rounded-full bg-fordBlue font-fordMedium">
            Заявете своя интерес
          </button>
        </form>
      </div> */}
    </div>
  );
}

export default Bronco;
