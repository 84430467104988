function ExportTableAsCSV({ tableData, fileName }) {
  const download = () => {
    const csvData = [];
    const headers = [];
    document.querySelectorAll("h4").forEach((h) => {
      headers.push(h.innerText);
    });
    csvData.push(headers.join(","));
    const rows = document.querySelectorAll(".offerTableRow");
    rows.forEach((row) => {
      const rowData = [];
      row.childNodes.forEach((node) => {
        rowData.push(node.innerText);
      });
      csvData.push(rowData.join(","));
    });
    const csv = csvData.join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <button
      onClick={() => download(fileName)}
      className="px-4 py-2 mb-4 border cursor-pointer border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
    >
      Експорт като CSV
    </button>
  );
}

export default ExportTableAsCSV;
