import SummerCampaignForm from "../components/SummerCampaignForm";

function Summer2024() {
  return (
    <>
      <img src="../images/summer2024/summer-campaign-2024.jpg" alt="" />
      <section className="w-full py-16 mx-auto bg-fordBlue">
        <h1 className="text-5xl font-bold tracking-wide text-center text-white uppercase">
          Оферти, по-горещи от лятото!
        </h1>
        <h3 className="mt-4 text-2xl text-center text-white">
          Това лято се пригответе за незабравими приключения с автомобил Ford!
          <br />
          Открийте перфектния автомобил за вашите летни пътувания и се
          възползвайте от нашите специални предложения.
        </h3>
        <h2 className="mt-4 text-2xl text-center text-white uppercase">
          Вземи своя Ford сега на специална цена!
        </h2>
      </section>
      {/* <section className="px-4 py-16 bg-gray-100 lg:px-48">
        <p className="text-lg text-center lg:px-24">
          Transit Custom е функционалният товарен автомобил, на който се
          доверяват милиони. Изцяло новият Transit Custom дава възможност на
          клиентите да изберат оптималния ван за своя бизнес, с високо ниво на
          стандартно оборудване, 7 години гаранция и множество технологии в
          помощ на водача.
        </p>
      </section>
      <section className="w-full px-4 py-16 mx-auto bg-fordBlue">
        <h2 className="mt-4 text-2xl tracking-wide text-center text-white">
          ALL-NEW TRANSIT CUSTOM Е ОТЛИЧЕН С НАГРАДАТА „МЕЖДУНАРОДЕН ВАН НА 2024
          Г.“
        </h2>
      </section>
      <section className="flex flex-col-reverse lg:flex-row">
        <div className="p-8 lg:w-1/2">
          <h3 className="text-xl tracking-wide uppercase text-fordBlue">
            Усъвършенствани технологии като стандартно оборудване
          </h3>
          <p className="mt-4 text-fordBlue">
            Усъвършенстваните системи в помощ на водача включват асистент за
            предотвратяване на сблъсък с автономно аварийно спиране,
            предупреждение за поддържане на лентата и интелигентен асистент за
            скорост. Ford SYNC 4 осигурява подобренасвързаност, задвижвана от
            нов 5G модем,13-инчов сензорен екран и стандартно гласово
            разпознаване.Нова технология Delivery автоматизира повтарящите се
            задачи за доставчиците, като спестява до 20 секунди от всяка
            доставка.
          </p>
        </div>
        <img
          className="lg:w-1/2"
          src="../images/transit-custom/multimedia.jpg"
          alt=""
        />
      </section>
      <section className="flex flex-col lg:flex-row">
        <img
          className="lg:w-1/2"
          src="../images/transit-custom/design.jpg"
          alt=""
        />
        <div className="p-8 bg-gray-100 lg:w-1/2">
          <h3 className="text-xl tracking-wide uppercase text-fordBlue">
            ИНТЕЛИГЕНТЕН ДИЗАЙН
          </h3>
          <p className="mt-4 text-fordBlue">
            Новият Transit Custom предлага подобрени пропорции, с по-ниска
            височина на стъпалото за по-лесно влизанев кабината и габаритна
            височина под 2 м, за да може автомобилът да влиза във всички закрити
            градски паркинги.
          </p>
        </div>
      </section>
      <section className="flex flex-col-reverse lg:flex-row">
        <div className="p-8 lg:w-1/2">
          <h3 className="text-xl tracking-wide uppercase text-fordBlue">
            ВАШИЯТ ПОДВИЖЕН ОФИС
          </h3>
          <p className="mt-4 text-fordBlue">
            Интелигентните дизайнерски добавки в Mobile Office включватудобно
            прибиране на устройството между седалките, допълнителни USB връзки и
            иновативна функция за накланяне на волана, която ви позволява да
            създадете бюро, когато сте паркирали, което е идеално за поставяне
            на лаптоп.
          </p>
        </div>
        <img
          className="lg:w-1/2"
          src="../images/transit-custom/office.jpg"
          alt=""
        />
      </section> */}
      {/* <section className="w-full py-16 mx-auto bg-fordBlue">
        <h2 className="mt-4 text-2xl tracking-wide text-center text-white">
          МЕЖДУНАРОДЕН ВАН НА 2024 Г.
        </h2>
        <p className="max-w-3xl mx-auto mt-4 text-lg tracking-wide text-center text-white">
          Изцяло новият Transit Custom, най-предпочитаният еднотонен ван в
          Европа, спечели престижната награда "Международен ван на годината"
          (IVOTY) 2024.
        </p>
      </section> */}
      {/* <img
        src="../images/transit-custom/FordTransitCustom_IVOTY2024.jpg"
        alt=""
      /> */}
      <section className="w-full xl:w-[1300px] mx-auto">
        <SummerCampaignForm />
      </section>
    </>
  );
}

export default Summer2024;
