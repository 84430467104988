import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { addDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase/config";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import LogoLoader from "../components/LogoLoader";

const DynamicPage = () => {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);

  const navigate = useNavigate();
  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const marketingCampaign = async (data, slug) => {
    await addDoc(collection(db, slug), {
      name: data.name,
      email: data.email,
      phone: data.phone,
      dealership: data.dealership,
      message: data.message,
      date: Timestamp.fromDate(new Date()),
      title: pageData.title,
    });
    emailjs
      .sendForm(
        "service_3ydm7sf",
        "template_tb2rj0l",
        "#templateCampaignForm",
        "YzmqGpemdgsw4KxkD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/thank-you-testdrive");
  };

  useEffect(() => {
    const fetchData = async () => {
      const q = query(
        collection(db, "LandingPages"),
        where("slug", "==", slug)
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const data = snapshot.docs[0].data();
        setPageData(data);
      } else {
        console.log("Страницата не беше намерена");
      }
    };
    fetchData();
  }, [slug]);

  if (!pageData) {
    return <LogoLoader />;
    //     return <div>Зареждане...</div>;
  }

  document.title = "FORD - " + pageData.title;
  // Функция за рендиране на блоковете
  const renderBlocks = () => {
    return pageData.blocks.map((block, index) => (
      <section
        key={index}
        className={`flex flex-col-reverse ${
          index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
        } bg-gray-100 w-full`}
      >
        <div className="p-8 lg:w-1/2">
          <h3 className="text-xl tracking-wide uppercase text-fordBlue">
            {block.title}
          </h3>
          <p className="mt-4 text-fordBlue">{block.text}</p>
          {block.button1Text && block.button1Url && (
            <a
              href={block.button1Url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 mt-4 text-white rounded bg-fordBlue hover:bg-fordLight"
            >
              {block.button1Text}
            </a>
          )}
          {block.button2Text && block.button2Url && (
            <a
              href={block.button2Url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block px-4 py-2 mt-4 ml-2 border rounded hover:border-fordLight text-fordBlue border-fordBlue hover:text-white hover:bg-fordLight"
            >
              {block.button2Text}
            </a>
          )}
        </div>
        <img
          className="w-full lg:w-1/2"
          src={block.imageUrl}
          alt={block.title}
        />
      </section>
    ));
  };

  // Функция за рендиране на контактната форма
  const renderContactForm = () => {
    const contactFields = pageData.contactFields || [];

    return (
      <form
        onSubmit={handleSubmit((data) => marketingCampaign(data, slug))}
        id="templateCampaignForm"
        className="px-8 py-16 lg:px-48"
        noValidate
      >
        <h2 className="text-xl tracking-wider text-center uppercase text-fordBlue">
          {pageData.formTitle}
        </h2>

        {/* Име */}
        {contactFields.includes("имена") ? (
          <div className="mt-8">
            <label htmlFor="name" className="text-gray-500">
              Име
            </label>
            <input
              type="text"
              id="name"
              name="name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Моля, въведете Вашето име",
                },
              })}
            />
            <p className="text-red-500">{errors.name?.message}</p>
          </div>
        ) : (
          <input type="hidden" name="name" value="" /> // Скрита стойност за липсващото поле
        )}

        {/* Имейл */}
        {contactFields.includes("имейл") ? (
          <div className="mt-4">
            <label htmlFor="email" className="text-gray-500">
              Имейл
            </label>
            <input
              type="email"
              id="email"
              name="email"
              {...register("email", {
                required: {
                  value: true,
                  message: "Моля, въведете имейл-адрес",
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Невалиден имейл-адрес",
                },
              })}
            />
            <p className="text-red-500">{errors.email?.message}</p>
          </div>
        ) : (
          <input type="hidden" name="email" value="" /> // Скрита стойност за липсващото поле
        )}

        {/* Телефон */}
        {contactFields.includes("телефон") ? (
          <div className="mt-4">
            <label htmlFor="phone" className="text-gray-500">
              Телефон
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              {...register("phone", {
                required: {
                  value: true,
                  message: "Моля, въведете телефонен номер",
                },
                pattern: {
                  value:
                    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                  message: "Невалиден телефонен номер",
                },
              })}
            />
            <p className="text-red-500">{errors.phone?.message}</p>
          </div>
        ) : (
          <input type="hidden" name="phone" value="" /> // Скрита стойност за липсващото поле
        )}

        {/* Съобщение */}
        {contactFields.includes("съобщение") ? (
          <div className="mt-4">
            <label htmlFor="message" className="text-gray-500">
              Съобщение
            </label>
            <textarea
              type="text"
              id="message"
              name="message"
              rows={6}
              {...register("message", {
                required: {
                  value: true,
                  message: "Моля, въведете Вашето съобщение",
                },
              })}
            />
            <p className="text-red-500">{errors.message?.message}</p>
          </div>
        ) : (
          <input type="hidden" name="message" value="" /> // Скрита стойност за липсващото поле
        )}

        {/* Предпочитано представителство */}
        {contactFields.includes("предпочитано представителство") ? (
          <div className="mt-4">
            <label htmlFor="dealership" className="text-gray-500">
              Предпочитано представителство
            </label>
            <select
              name="dealership"
              id="dealership"
              {...register("dealership", {
                required: {
                  value: true,
                  message: "Моля, изберете предпочитано представителство",
                },
              })}
            >
              <option value="" disabled selected>
                Моля, изберете
              </option>
              <option value="liulin2@motopfohe.bg">София Люлин</option>
              <option value="mladost@motopfohe.bg">София Младост</option>
              <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
              <option value="burgas@motopfohe.bg">Бургас</option>
              <option value="varna@motopfohe.bg">Варна</option>
              <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
              <option value="gabrovo@motopfohe.bg">Габрово</option>
              <option value="dobrich@motopfohe.bg">Добрич</option>
              <option value="montana@motopfohe.bg">Монтана</option>
              <option value="pleven@motopfohe.bg">Плевен</option>
              <option value="plovdiv@motopfohe.bg">Пловдив</option>
              <option value="russe@motopfohe.bg">Русе</option>
              <option value="starazagora@motopfohe.bg">Стара Загора</option>
              <option value="smolyan@motopfohe.bg">Смолян</option>
              <option value="forsh@motopfohe.bg">Шумен</option>
            </select>
            <p className="text-red-500">{errors.dealership?.message}</p>
          </div>
        ) : (
          <input type="hidden" name="dealership" value="" /> // Скрита стойност за липсващото поле
        )}

        {/* Скрито поле за заглавие на кампанията */}
        <input
          type="hidden"
          name="title"
          value={typeof pageData?.title === "string" ? pageData.title : ""}
        />
        <button
          type="submit"
          className="px-16 py-2 mx-auto mt-8 text-white border rounded-full bg-fordBlue"
        >
          Изпрати
        </button>
      </form>
    );
  };

  return (
    <>
      {pageData.isPublished ? (
        <>
          <img src={pageData.desktopImage} alt={slug} />
          <section className="text-5xl font-bold tracking-wide text-center text-white uppercase">
            <h1 className="px-12 pt-10 text-xl md:text-3xl bg-fordBlue">
              {pageData.title}
            </h1>
          </section>
          <section className="py-8 text-center text-white bg-fordBlue">
            <p className="max-w-4xl mx-auto text-lg">{pageData.description}</p>
          </section>
          {pageData.buttonUrl ? (
            <section className="my-16 text-center">
              <a
                className="px-8 py-4 tracking-wider text-white rounded-full bg-fordBlue hover:bg-fordLight"
                href={pageData.buttonUrl}
                target="_blank"
                rel="noreferrer"
              >
                Виж наличните автомобили
              </a>
            </section>
          ) : (
            <></>
          )}

          {/* Рендиране на блоковете */}
          <div className="w-full mx-auto">{renderBlocks()}</div>

          <section className="w-full xl:w-[1300px] mx-auto py-8">
            {renderContactForm()}
          </section>
          {pageData.disclaimer ? (
            <p className="ml-12 text-sm text-gray-500">{pageData.disclaimer}</p>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p className="my-12 text-lg text-center text-red-600">
          Тази страница не е публична
        </p>
      )}
    </>
  );
};

export default DynamicPage;
