import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogin } from "../hooks/useLogin";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { error, login } = useLogin();

  const handleLogin = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const { user } = useAuthContext();
  if (user) {
    return <Navigate to="/admin" />;
  }

  return (
    <div className="flex flex-col items-center justify-center p-32">
      <h2 className="text-2xl text-fordBlue">Вписване</h2>
      <form action="" className="mt-8" onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Имейл</label>
          <input
            type="email"
            placeholder="someone@motopfohe.bg"
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password">Парола</label>
          <input
            type="password"
            required
            value={password}
            className="block w-full border-gray-300 rounded-lg focus:ring-0 focus:border-gray-300"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="w-full py-2 text-center text-white rounded-full bg-fordBlue"
          >
            Вход
          </button>
        </div>
        {error && <p>{error}</p>}
      </form>
    </div>
  );
}
