import TransitConnectForm from "../components/TransitConnectForm";

function TransitConnect() {
  return (
    <>
      <div className="bg-[url('../public/images/transit-connect/transitconnect-hero.jpg')] bg-cover bg-center lg:bg-center pt-16 lg:pt-[80px] ">
        <h1 className="mr-4 text-3xl font-bold text-white uppercase lg:mr-32 text-end lg:pl-20 lg:text-7xl">
          Супер оферта!
        </h1>
        <p className="lg:mt-4 mr-4 lg:mr-32 text-xl font-bold tracking-widest text-white uppercase lg:pl-20 lg:text-4xl text-end pb-48 lg:pb-[450px]">
          Само за 41 000лв
          <span className="text-sm align-super ">*</span>
        </p>
        <p className="pb-4 ml-4 text-sm text-white">
          *Важи за ограничено количество автомобили на склад
        </p>
      </div>
      <section className="w-full xl:w-[920px] mx-auto bg-gray-100 p-8 mt-16">
        <h2 className="text-3xl text-center uppercase text-fordBlue">
          Грабни възможността и се увери в надеждността на Ford!
        </h2>
        <p className="mt-8 leading-7">
          Издръжливостта и функционалността са запазената марка на Transit и
          винаги ще бъдат характерен почерк на лекотоварните автомобили на Ford,
          предназначени да ви помогнат да работите по-ефективно. Ford Transit
          Connect е модерни технологии и интелигентни решения като иновативната
          преграда за товарене и гъвкава седалка за пасажера. С опции за късо и
          дълго междуосие, осигуряващи товарни обеми до 3,6 m³ (VDA), полезен
          товароподемност до 982 kg и стилове на каросерията, включително ван и
          двойна кабина във ван. Това е изключително усъвършенстван среден по
          размер ван, оборудван с ценни технологии за подпомагане на шофирането.
        </p>
      </section>
      <section className="w-full xl:w-[1300px] mx-auto">
        <TransitConnectForm />
      </section>
      <p className="mt-8 ml-4 text-xs text-fordBlue">
        * Цената е валидна за Ford Transit Connect Trend 1.5 EcoBlue Дизел
        100к.с., 6-степенна ръчна трансмисия през Мото-Пфое лизинг, само за
        юридически лица. Снимката е с илюстративна цел. Важи за ограничено
        количество коли на склад.
      </p>
    </>
  );
}

export default TransitConnect;
