import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import LandingPageForm from "../components/LandingPageForm";

const EditLandingPage = () => {
  const { id } = useParams();
  const [pageData, setPageData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPageData = async () => {
      const pageRef = doc(db, "LandingPages", id);
      const pageDoc = await getDoc(pageRef);
      if (pageDoc.exists()) {
        setPageData({ id: pageDoc.id, ...pageDoc.data() });
      }
    };
    fetchPageData();
  }, [id]);

  const handlePageEdit = () => {
    navigate("/admin/landingpages"); // Пренасочва обратно към списъка след редакция
  };

  return (
    <div>
      <h2 className="mb-4 text-xl tracking-wider uppercase">
        Редактирай лендинг-страница
      </h2>
      {pageData ? (
        <LandingPageForm
          existingPageData={pageData}
          onSubmit={handlePageEdit}
        />
      ) : (
        <p>Зареждане на данните...</p>
      )}
    </div>
  );
};

export default EditLandingPage;
