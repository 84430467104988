import { useState } from "react";
import { Link } from "react-router-dom";
import { GetInTouch } from "../components/GetInTouch";
import { ProposalForm } from "../components/ProposalForm";

function Puma() {
  const model = "Puma";
  document.title = "FORD Puma за 3900 лв. първоначални разходи";
  const [touchFormVisibility, setTouchFormVisibility] = useState(false);
  const [proposalFormVisibility, setProposalFormVisibility] = useState(false);

  const getinTouch = () => {
    setTouchFormVisibility(true);
    setProposalFormVisibility(false);
  };
  const proposal = () => {
    setTouchFormVisibility(false);
    setProposalFormVisibility(true);
  };

  return (
    <div className="container max-w-[1440px] mx-auto shadow-xl">
      {/* <img className="w-full" src="./images/puma-promo-banner.png" alt="" />
      <div className="flex flex-col gap-8 px-8 lg:flex-row py-14">
        <div className="flex flex-col-reverse gap-3 p-6 shadow-xl lg:flex-row lg:w-1/2">
          <div className="flex-col lg:w-1/2 font-FordLight">
            <p className="text-gray-700">
              Удивителен дизайн. Спортни и динамични линии. Компактен отвън,
              просторен отвътре
            </p>
          </div>
          <div className="shadow-xl lg:w-1/2">
            <img src="./images/2019_FORD_PUMA_ST-Line_19.jpg" alt="" />
          </div>
        </div>
        <div className="flex flex-col-reverse gap-3 p-6 shadow-xl lg:flex-row lg:w-1/2">
          <div className="flex-col lg:w-1/2 font-FordLight">
            <p className="text-gray-700">
              За да се насладиш на всички удоволствия, които живота ти поднася,
              избери сега Ford Puma, с допълнително място, за всичко, което
              обичаш.
            </p>
          </div>
          <div className="shadow-xl lg:w-1/2">
            <img
              src="./images/ford-puma-eu-BX726_19MY_CHS-99_SHOT_14_0058-16x9-2160x1215-FC_D_T_M_Utility.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="p-12 text-center text-white bg-fordBlue">
        <div className="py-6 lg:px-40">
          <h1 className="text-2xl tracking-wider lg:text-4xl font-FordLight">
            Ford Puma сега с първоначални разходи до ключ за 3 900 лв.!
          </h1>

          <p className="mt-5 text-sm text-center lg:px-16 font-FordLight">
            Началният лизингов разход включва всички необходими начални разходи
            до ключ и е формиран при финансов лизинг с опция за придобиване от
            „Мото-Пфое Лизинг“ за период от 5 години, с 5% авансова вноска и
            включени: Застраховка ГО, първа вноска от общо четири, регистрация в
            КАТ и продуктова такса. Цената е валидна за ограничено количество
            автомобили на склад. Важи за покупка на до три автомобила на клиент.
          </p>

          <div className="flex flex-col justify-center gap-6 mt-12 lg:gap-36 lg:flex-row font-FordLight">
            <button
              className="px-10 py-2 border rounded-full bg-fordBlue hover:bg-gray-200 hover:text-fordBlue"
              onClick={getinTouch}
            >
              Свържи се с търговец
            </button>

            <button
              className="bg-fordBlue px-[40px] py-2 border  hover:bg-gray-200 hover:text-fordBlue rounded-full"
              onClick={proposal}
            >
              Поискай оферта
            </button>
            <button className="bg-fordBlue px-[40px] py-2 border  hover:bg-gray-200 hover:text-fordBlue rounded-full">
              <Link to="/?model=puma">Виж наличните на склад</Link>
            </button>
          </div>
        </div>
      </div>
      {touchFormVisibility && <GetInTouch model={model} />}
      {proposalFormVisibility && <ProposalForm model={model} />} */}
      <h2 className="mx-auto text-center p-80">
        Тази промоция вече не е актуална. Може да разгледате другите ни
        <Link to="/" className="text-fordLight">
          {" "}
          налични предложения
        </Link>
        .
      </h2>
    </div>
  );
}

export default Puma;
