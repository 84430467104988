import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config";

function Testdrive() {
  document.title = "FORD Testdrive";
  const navigate = useNavigate();

  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const testdriveRequest = async (data) => {
    await addDoc(collection(db, "testdrivePage"), {
      name: data.name,
      email: data.email,
      phone: data.phone,
      dealership: data.dealership,
      model: data.model,
      lice: data.lice,
      date: Timestamp.fromDate(new Date()),
    });
    emailjs
      .sendForm(
        "service_3ydm7sf",
        "template_vtd7gk2",
        "#testdriveForm",
        "YzmqGpemdgsw4KxkD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/thank-you-testdrive");
  };

  return (
    <>
      <img src="/images/ford-service-6-years.jpg" alt="" />
      <div className="container max-w-[1140px] mx-auto shadow-xl font-FordLight py-8">
        <h1 className="mt-8 text-4xl text-center uppercase text-fordBlue">
          Заявка за тестдрайв
        </h1>
        <form
          className="p-8 lg:p-16"
          onSubmit={handleSubmit(testdriveRequest)}
          id="testdriveForm"
          noValidate
        >
          <label for="name" className="block mt-8 text-gray-500">
            Име *
          </label>
          <input
            type="name"
            id="name"
            name="name"
            className="w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("name", {
              required: {
                value: true,
                message: "Моля, въведете Вашето име",
              },
            })}
          />
          <p className="text-red-500">{errors.name?.message}</p>
          <label for="email" className="block mt-4 text-gray-500">
            Имейл *
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("email", {
              required: {
                value: true,
                message: "Моля, въведете имейл-адрес",
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Невалиден имейл-адрес",
              },
            })}
          />
          <p className="text-red-500">{errors.email?.message}</p>
          <label for="phone" className="block mt-4 text-gray-500">
            Телефон *
          </label>
          <input
            type="phonenumber"
            id="phone"
            name="phone"
            className="w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("phone", {
              required: {
                value: true,
                message: "Моля, въведете телефонен номер",
              },
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                message: "Невалиден телефонен номер",
              },
            })}
          />
          <p className="text-red-500">{errors.phone?.message}</p>
          <label for="model" className="block mt-4 text-gray-500">
            Модел *
          </label>
          <select
            name="model"
            id="model"
            className="block w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("model", {
              required: {
                value: true,
                message: "Моля, изберете предпочитан модел",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="fiesta">Fiesta</option>
            <option value="Focus">Focus</option>
            <option value="Puma">Puma</option>
            <option value="Mondeo">Mondeo</option>
          </select>
          <p className="text-red-500">{errors.model?.message}</p>
          <label for="filial" className="block mt-4 text-gray-500">
            Филиал *
          </label>
          <select
            name="dealership"
            id="dealership"
            className="w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("dealership", {
              required: {
                value: true,
                message: "Моля, изберете предпочитано представителство",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="liulin2@motopfohe.bg">София Люлин</option>
            <option value="mladost@motopfohe.bg">София Младост</option>
            <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
            <option value="burgas">Бургас</option>
            <option value="varna@motopfohe.bg">Варна</option>
            <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
            <option value="gabrovo@motopfohe.bg">Габрово</option>
            <option value="dobrich@motopfohe.bg">Добрич</option>
            <option value="montana@motopfohe.bg">Монтана</option>
            <option value="pleven@motopfohe.bg">Плевен</option>
            <option value="plovdiv@motopfohe.bg">Пловдив</option>
            <option value="russe@motopfohe.bg">Русе</option>
            <option value="starazagora@motopfohe.bg">Стара Загора</option>
            <option value="smolyan@motopfohe.bg">Смолян</option>
            <option value="forsh@motopfohe.bg">Шумен</option>
          </select>
          <p className="text-red-500">{errors.dealership?.message}</p>
          <label for="lice" className="block mt-4 text-gray-500">
            Вид лице *
          </label>
          <select
            name="lice"
            id="lice"
            className="w-full px-4 py-3 border border-gray-200 rounded-md"
            {...register("lice", {
              required: {
                value: true,
                message: "Моля, изберете физическо или юридическо лице",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="fizichesko">Физическо лице</option>
            <option value="uridichesko">Юридическо лице</option>
          </select>
          <p className="text-red-500">{errors.lice?.message}</p>
          <div className="flex-row w-full mt-12 text-gray-500">
            <input
              type="checkbox"
              id="suglasen"
              name="suglasen"
              value="suglasen"
            />
            <label for="suglasen" className="p-4">
              Съгласен/на съм с политиката за поверителност /задължително поле/
            </label>
          </div>
          <div className="flex-row mt-1 text-gray-500">
            <input type="checkbox" id="iskam" name="iskam" value="iskam" />
            <label for="iskam" className="p-4">
              Искам да ме информирате по имейл за продукти и оферти
            </label>
          </div>
          <button className="w-full p-4 mt-12 text-lg tracking-wider text-white uppercase rounded-full bg-fordBlue">
            Изпращане
          </button>
        </form>
      </div>
    </>
  );
}

export default Testdrive;
