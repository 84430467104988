const TextInput = ({ label, value, onChange, required = false }) => (
  <div className="mb-4">
    <label className="block text-left">{label}</label>
    <input
      type="text"
      value={value}
      onChange={onChange}
      className="w-full px-4 py-2 border rounded"
      required={required}
    />
  </div>
);

export default TextInput;
