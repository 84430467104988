import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

import { NavLink, useLoaderData } from "react-router-dom";

import { motion } from "framer-motion";

import CarList from "./CarList";
import Filter from "./Filter";
import { useState } from "react";

export default function Home() {
  document.title = "FORD - Налични автомобили";

  const cars = useLoaderData();
  const [x, setX] = useState("-100vw");
  const closeFilter = () => {
    setX("-100vw");
  };

  return (
    <>
      {x === "-100vw" && (
        <p
          className="p-4 mx-4 mt-4 text-center text-white bg-fordBlue lg:hidden"
          onClick={() => setX(0)}
        >
          Филтър
        </p>
      )}
      {x === 0 && (
        <p
          className="p-4 mx-4 mt-4 text-center text-white bg-fordBlue lg:hidden"
          onClick={closeFilter}
        >
          Виж резултатите
        </p>
      )}
      <div className="grid grid-cols-1 gap-4 p-4 xl:grid-cols-4">
        <motion.div
          className="absolute z-10 h-['100vh'] col-span-1 w-80 shadow-xl bg-gray-50 lg:hidden"
          animate={{ x }}
          transition={{ duration: 0.2, type: "tween" }}
        >
          {cars && <Filter cars={cars} closeFilter={closeFilter} />}
        </motion.div>
        <div className="z-50 hidden h-full col-span-1 w-80 bg-gray-50 lg:block">
          {cars && <Filter cars={cars} />}
        </div>
        <div className="z-0 col-span-3">{cars && <CarList cars={cars} />}</div>
      </div>
    </>
  );
}

export const carsLoader = async () => {
  let results = [];
  const querySnapshot = await getDocs(collection(db, "cars"));
  querySnapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });
  return results;
};
