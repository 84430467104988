import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config";

function RangerRaptor() {
  document.title = "FORD Ranger Raptor";
  const navigate = useNavigate();

  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const getinTouchRangerRaptor = async (data) => {
    await addDoc(collection(db, "raptorTestdrive"), {
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      dealership: data.dealership,
      date: Timestamp.fromDate(new Date()),
    });
    emailjs
      .sendForm(
        "service_3ydm7sf",
        "template_jbwubns",
        "#rangerRaptorForm",
        "YzmqGpemdgsw4KxkD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/thank-you-testdrive");
  };

  return (
    <>
      <div className="lg:min-h-[750px] bg-[url('../public/images/Ranger_Raptor_Hero.jpg')]  bg-center lg:bg-center p-0 lg:p-16 bg-cover bg-no-repeat">
        <h2 className="hidden text-3xl text-white uppercase lg:block font-fordMedium text-end">
          Ford Ranger Raptor
        </h2>
        <h1 className="hidden text-3xl text-white lg:block font-fordMedium text-end">
          Поразяващо присъствие, внушителни размери и екстремен стил
        </h1>
        <p className="hidden max-w-sm px-4 py-2 mt-4 ml-auto text-2xl text-center text-white border border-white rounded-full hover:cursor-pointer hover:bg-fordBlue hover:text-white lg:block hover:border-fordBlue">
          Опитай сега! Заяви тестдрайв!
        </p>
      </div>
      <div className="mx-auto lg:hidden bg-[url('../public/images/Ranger_Raptor_Hero_mobile.jpg')] bg-cover pt-8 bg-no-repeat bg-center pb-72">
        <h2 className="text-3xl text-center text-white uppercase font-fordMedium">
          Ford Ranger Raptor
        </h2>
        <h1 className="px-4 text-xl text-center text-white font-fordMedium">
          Поразяващо присъствие, внушителни размери и екстремен стил
        </h1>
        <p className="px-4 py-2 mx-16 mt-4 text-center text-white border border-white rounded-full text-ZZxl">
          Опитай сега! Заяви тестдрайв!
        </p>
      </div>
      <div className="p-16 text-center bg-fordBlue">
        <a
          href="#rangerRaptorForm"
          className="max-w-sm px-16 py-2 text-xl tracking-wider text-center text-white border border-white rounded-full shadow-sm hover:cursor-pointer hover:bg-white hover:text-fordBlue"
        >
          Свържи се с търговец
        </a>
      </div>
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">FOX SHOCKS</h3>
          <p className="mt-4">
            Изцяло новият Ranger Raptor е най-добрият офроудър. Неговите спортни
            амортисьори Fox® означават, че окачването може да поеме всякакво
            натоварване.
          </p>
        </div>
        <img
          src="../images/ranger-raptor/fox-shocks.webp"
          alt=""
          className="lg:w-1/2"
        />
      </div>
      <div className="flex flex-col lg:flex-row">
        <img
          src="../images/ranger-raptor/Ford-Ranger-Raptor-пикап-за-различен-терен-1536x862.jpg"
          alt=""
          className="lg:w-1/2"
        />
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">
            СИСТЕМА ЗА УПРАВЛЕНИЕ НА ТЕРЕНА
          </h3>
          <p className="mt-4">
            За да ви помогне да се възползвате максимално от възможностите на
            Raptor 4×4, системата за управление на терена ви позволява да
            регулирате ускорението, контрола на сцеплението, чувствителността на
            кормилното управление, поведението при смяна на предавките и др.
            Изберете от шест различни режима, за да увеличите максимално
            производителността при всякакви условия – нормален, спортен, режим
            на трева, чакъл и сняг, режим на кал и пясък, режим на скала и режим
            Baja за ненадминато офроуд изживяване.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">
            ЛЕСНО ПОВДИГАНЕ НА ЗАДНАТА ВРАТА
          </h3>
          <p className="mt-4">
            Raptor вече се предлага с Easy Lift задна врата. Интелигентният
            дизайн прави задната врата на вашия пикап 4×4 значително по-лека и
            ви позволява да я отваряте и затваряте с лекота.
          </p>
        </div>
        <img
          src="../images/ranger-raptor/задна-врата-1536x862.jpg"
          alt=""
          className="lg:w-1/2"
        />
      </div>
      <div className="p-16 text-center bg-fordBlue">
        <a
          href="#rangerRaptorForm"
          className="max-w-sm px-16 py-2 text-xl tracking-wider text-center text-white border border-white rounded-full shadow-sm hover:cursor-pointer hover:bg-white hover:text-fordBlue"
        >
          Направи запитване
        </a>
      </div>
      <div className="flex flex-col lg:flex-row">
        <img
          src="../images/ranger-raptor/Ranger_Raptor-group-03-1536x1024.jpg"
          alt=""
          className="lg:w-1/2"
        />
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">
            УНИКАЛНА РЕШЕТКА, БРОНИ И ИНТЕРИОРНИ ХАРАКТЕРИСТИКИ
          </h3>
          <p className="mt-4">
            Уникалната решетка на Ford Ranger Raptor, алуминиеви джанти с гуми
            за всякакви терени, както и предната и задна броня придават на
            Raptor специфичният му вид. Решетката на Raptor с удебелени букви
            „FORD“ предава дръзко излъчване. Гумите за всякакъв терен правят
            Raptor лесно проходим при всички условия. Уникалните брони на Raptor
            завършват агресивния екстериор. Отвътре неповторимите отопляеми
            седалки, волан и табло правят Ford Ranger Raptor луксозен пикап.
          </p>
        </div>
      </div>
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">КОНТРОЛ НА СПУСКАНЕТО</h3>
          <p className="mt-4">
            Hill Descent Control е проектиран да регулира скоростта ви на
            спускане при офроуд условия, без дори да се налага да докосвате
            педалите на спирачката или газта, и работи както на предни, така и
            на задни предавки.
          </p>
        </div>
        <img
          src="../images/ranger-raptor/Ranger_Raptor-Code_Orange-11-1536x1024.jpg"
          alt=""
          className="lg:w-1/2"
        />
      </div>
      <div className="flex flex-col lg:flex-row">
        <img
          src="../images/ranger-raptor/планки-1536x862.jpg"
          alt=""
          className="lg:w-1/2"
        />
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide">
            ПЛАНКИ ЗА СМЯНА НА СКОРОСТИТЕ
          </h3>
          <p className="mt-4">
            Вдъхновени от производителността магнезиеви лостчета за смяна на
            предавките осигуряват бърза и лесна смяна на предавките на една ръка
            разстояние. И тъй като са монтирани на волана, можете да сменяте
            предавките по-ефективно и без да сваляте ръцете си от волана, където
            и да шофирате.
          </p>
        </div>
      </div>
      <div className="p-16 text-center bg-fordBlue">
        <a
          href="https://ford.bg/all-new-ranger-raptor"
          target="_blank"
          className="max-w-sm px-16 py-2 text-xl tracking-wider text-center text-white border border-white rounded-full shadow-sm hover:cursor-pointer hover:bg-white hover:text-fordBlue"
        >
          Научи повече за Raptor
        </a>
      </div>
      <img
        src="../images/ranger-raptor/2022FordRangerRaptor_11-scaled-q7cwsbacsp7poc0e8ie0jd3fzjwem3lv89zyf8qfka.jpg"
        alt=""
      />

      <form
        onSubmit={handleSubmit(getinTouchRangerRaptor)}
        id="rangerRaptorForm"
        className="px-8 py-16 lg:px-48"
        noValidate
      >
        <h2 className="text-xl tracking-wider text-center uppercase text-fordBlue">
          Заявете тестдрайв
        </h2>
        <div className="mt-8">
          <label htmlFor="userName" className="text-gray-500">
            Име
          </label>
          <input
            type="text"
            id="userName"
            name="userName"
            {...register("userName", {
              required: {
                value: true,
                message: "Моля, въведете Вашето име",
              },
            })}
          />
          <p className="text-red-500">{errors.userName?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="email" className="text-gray-500">
            Имейл
          </label>
          <input
            type="email"
            id="email"
            name="email"
            {...register("email", {
              required: {
                value: true,
                message: "Моля, въведете имейл-адрес",
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Невалиден имейл-адрес",
              },
            })}
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="phone" className="text-gray-500">
            Телефон
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            {...register("phone", {
              required: {
                value: true,
                message: "Моля, въведете телефонен номер",
              },
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                message: "Невалиден телефонен номер",
              },
            })}
          />
          <p className="text-red-500">{errors.phone?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="dealership" className="text-gray-500">
            Предпочитано представителство
          </label>
          <select
            name="dealership"
            id="dealership"
            {...register("dealership", {
              required: {
                value: true,
                message: "Моля, изберете предпочитано представителство",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="liulin2@motopfohe.bg">София Люлин</option>
            <option value="mladost@motopfohe.bg">София Младост</option>
            <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
            <option value="burgas@motopfohe.bg">Бургас</option>
            <option value="varna@motopfohe.bg">Варна</option>
            <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
            <option value="gabrovo@motopfohe.bg">Габрово</option>
            <option value="dobrich@motopfohe.bg">Добрич</option>
            <option value="montana@motopfohe.bg">Монтана</option>
            <option value="pleven@motopfohe.bg">Плевен</option>
            <option value="plovdiv@motopfohe.bg">Пловдив</option>
            <option value="russe@motopfohe.bg">Русе</option>
            <option value="starazagora@motopfohe.bg">Стара Загора</option>
            <option value="smolyan@motopfohe.bg">Смолян</option>
            <option value="forsh@motopfohe.bg">Шумен</option>
          </select>
          <p className="text-red-500">{errors.dealership?.message}</p>
        </div>
        <button
          type="submit"
          className="px-16 py-2 mx-auto mt-8 text-white border rounded-full bg-fordBlue"
        >
          Изпрати
        </button>
      </form>
    </>
  );
}

export default RangerRaptor;
