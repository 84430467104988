import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";
import { useState } from "react";
import { GetInTouch } from "../components/GetInTouch";

export default function NewTransitCourier() {
  const model = "Transit Courier";
  const copy = "l.koleva@motopfohe.bg";
  document.title = "Новият Ford Transit Courier";

  const [showModal, setShowModal] = useState(false);
  const [zapitvane, setZapitvane] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    setZapitvane(false);
  };

  return (
    <div>
      <img
        src="../images/new-transit-courier/Ford Transit Courier Hero.jpg"
        alt=""
      />
      <section className="p-4 lg:p-12 lg:px-36">
        <p className="mt-8 text-2xl font-bold leading-8 tracking-widest text-center text-fordBlue">
          ИЗЦЯЛО НОВИЯТ FORD TRANSIT COURIER
        </p>
        <p className="mt-8 text-2xl leading-8 tracking-widest text-center text-fordBlue">
          Повече пространство за разрастване на бизнеса ви.
        </p>
      </section>
      <section className="p-8 lg:p-12 lg:px-64 bg-fordBlue">
        <p className="text-lg text-center text-white">
          Все още компактен отвън, новият Transit Courier вече е по-дълъг,
          по-широк и по-висок. Освен това е и по-интелигентен. Освен че сме
          направили място за два европалета, ние сме повишили нивото с
          усъвършенствани възможности за свързване, сигурност и технологии.
        </p>
      </section>
      <section>
        <div className="flex flex-col lg:flex-row">
          <img
            src="../images/new-transit-courier/ford-transit-courier-volume.jpg"
            alt=""
            className="lg:w-1/2"
          />
          <div className="p-4 lg:p-16 lg:w-1/2 bg-gray-50">
            <h3 className="text-xl tracking-wide uppercase">OБЕМ</h3>
            <p className="mt-4">
              Дължината на товарния отсек от 1,8 м, с разстояние между арките на
              колелата от 1,22 м, е проектирана така, че да побира два
              европалета и максимален полезен товар от 850 кг. Повишаването на
              височината на тавана до 1,25 м допринася за големия обем.
              E-Transit Courier дори разполага с допълнително 50 л достъпно
              място за съхранение в предния багажник.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse lg:flex-row">
          <div className="p-4 lg:p-16 lg:w-1/2 ">
            <h3 className="text-xl tracking-wide uppercase">Свързаност</h3>
            <p className="mt-4">
              Transit Courier е оборудван с най-новата система SYNC 4 с 8" или
              12" сензорен екран, изцяло свързан с мобилно устройство, системи
              за подпомагане на водача и информация за състоянието на автомобила
            </p>
          </div>
          <img
            src="../images/new-transit-courier/ford-transit-courier-connectivity.jpg"
            alt=""
            className="lg:w-1/2"
          />
        </div>
        <div className="flex flex-col lg:flex-row">
          <img
            src="../images/new-transit-courier/ford-transit-courier-assistance.jpg"
            alt=""
            className="lg:w-1/2"
          />
          <div className="p-4 lg:p-16 lg:w-1/2 bg-gray-50">
            <h3 className="text-xl tracking-wide uppercase">
              ПОМОЩ ПРИ УПРАВЛЕНИЕ
            </h3>
            <p className="mt-4">
              Transit Courier е снабден с усъвършенствани системи, които ви
              помагат в работния ден. Интелигентните системи за подпомагане на
              шофирането, включително адаптивен круиз контрол с поддържане на
              лентата за движение, асистент за предотвратяване на сблъсък,
              интелигентен асистент за намаляване на скоростта и асистент за
              паркиране - правят шофирането и работата по-лесни, по-интелигентни
              и по-безопасни.
            </p>
          </div>
        </div>
      </section>
      {/* <section className="p-8 lg:p-12 lg:px-36 bg-fordBlue">
        <p className="text-2xl text-center text-white font-fordMedium">
          Задава се революционно решение за всички нужди на бизнеса – Новият
          Transit Courier!
          <br />
          Безкомпромисни възможности, издръжливост и продуктивност.
        </p>
      </section> */}
      <section className="flex flex-col items-center px-4 py-12 lg:justify-center lg:p-16 lg:flex-row">
        <a
          href="https://ford.bg/commercial_cars/new-transit-courier"
          target="_blank"
        >
          <button className="px-12 py-2 mx-8 border rounded-full border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white">
            Виж повече за модела
          </button>
        </a>
        <button
          className="px-12 py-2 mx-8 mt-8 border rounded-full lg:mt-auto border-fordBlue text-fordBlue hover:bg-fordBlue hover:text-white"
          onClick={() => {
            setShowModal(true);
            setZapitvane(true);
            window.scrollTo(0, 0);
          }}
        >
          Свържи се с търговец
        </button>
      </section>
      {/* <img
        src="./images/new-transit-courier/ford-transit-courier-front-grill.jpg"
        alt=""
      />
      <section className="p-4 -mb-8 lg:px-24 bg-gray-50">
        <p className="text-sm text-center">
          * Цената е валидна за Transit Courier Van ниво на оборудване Trend,
          двигател 1.0L EcoBoost, 100 к.с., 6 степенна механична скоростна
          кутия, с предно предаване и важи само за юридически лица.
        </p>
      </section> */}
      {showModal && zapitvane && (
        <Modal handleClose={handleClose}>
          <GetInTouch model={model} copy={copy} />
        </Modal>
      )}
    </div>
  );
}
