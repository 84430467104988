import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Timestamp, addDoc, collection } from "firebase/firestore";
import { db } from "../firebase/config";

function MustangTestDrive() {
  document.title = "FORD Mustang Testdrive";
  const navigate = useNavigate();

  const form = useForm();
  const { register, handleSubmit, formState } = form;
  const { errors } = formState;

  const mustangTestdrive = async (data) => {
    await addDoc(collection(db, "mustangTestdrive"), {
      userName: data.userName,
      email: data.email,
      phone: data.phone,
      dealership: data.dealership,
      date: Timestamp.fromDate(new Date()),
    });
    emailjs
      .sendForm(
        "service_3ydm7sf",
        "template_05euyil",
        "#mustangForm",
        "YzmqGpemdgsw4KxkD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    navigate("/thank-you-testdrive");
  };

  return (
    <>
      <div>
        <img src="../images/mustang-testdrive/Mustang-Test-Drive.jpg" alt="" />
      </div>
      <section className="p-16 text-center lg:px-48 bg-fordBlue">
        <h2 className="text-xl tracking-wider text-white uppercase">
          Представяме ви 7-мо поколение спортен автомобил Ford Mustang
          <span className="text-xs align-super">®</span>
        </h2>
        <p className="mx-auto mt-4 tracking-wide text-white lg:w-2/3">
          Оборудван с 5.0l V8 двигател и емблематичния звук на Mustang.
          Ултрамодерен интериор с персонализиран дигитален дисплей, създава
          усещане за управление на реактивен самолет.
        </p>
      </section>
      <section className="flex flex-col-reverse lg:flex-row">
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide uppercase">
            Вдъхновен от първото поколение Mustang
          </h3>
          <p className="mt-4">Елегантен силует и мускулеста стойка</p>
        </div>
        <img
          src="../images/mustang-testdrive/Mustang-Back.jpg"
          alt=""
          className="lg:w-1/2"
        />
      </section>
      <section className="flex flex-col lg:flex-row">
        <img
          src="../images/mustang-testdrive/Mustang-Drivewheel.jpg"
          alt=""
          className="lg:w-1/2"
        />
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide uppercase">
            Вдъхновен от състезателните писти
          </h3>
          <p className="mt-4">
            Волан с плоско дъно за максимално представяне на пътя.
          </p>
        </div>
      </section>
      <div className="flex flex-col-reverse lg:flex-row">
        <div className="p-16 lg:w-1/2 bg-gray-50">
          <h3 className="text-xl tracking-wide uppercase">
            6-степенна ръчна или 10-степенна автоматична трансмисия
          </h3>
          <p className="mt-4">
            С възможност за SelectShift™ и активиране на лоста за смяна на
            предавките.
          </p>
        </div>
        <img
          src="../images/mustang-testdrive/Mustang-Gearliver.jpg"
          alt=""
          className="lg:w-1/2"
        />
      </div>
      <form
        onSubmit={handleSubmit(mustangTestdrive)}
        id="mustangForm"
        className="px-8 py-16 lg:px-48"
        noValidate
      >
        <h2 className="text-xl tracking-wider text-center uppercase text-fordBlue">
          Заявете тестдрайв
        </h2>
        <div className="mt-8">
          <label htmlFor="userName" className="text-gray-500">
            Име
          </label>
          <input
            type="text"
            id="userName"
            name="userName"
            {...register("userName", {
              required: {
                value: true,
                message: "Моля, въведете Вашето име",
              },
            })}
          />
          <p className="text-red-500">{errors.userName?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="email" className="text-gray-500">
            Имейл
          </label>
          <input
            type="email"
            id="email"
            name="email"
            {...register("email", {
              required: {
                value: true,
                message: "Моля, въведете имейл-адрес",
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: "Невалиден имейл-адрес",
              },
            })}
          />
          <p className="text-red-500">{errors.email?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="phone" className="text-gray-500">
            Телефон
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            {...register("phone", {
              required: {
                value: true,
                message: "Моля, въведете телефонен номер",
              },
              pattern: {
                value:
                  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
                message: "Невалиден телефонен номер",
              },
            })}
          />
          <p className="text-red-500">{errors.phone?.message}</p>
        </div>
        <div className="mt-4">
          <label htmlFor="dealership" className="text-gray-500">
            Предпочитано представителство
          </label>
          <select
            name="dealership"
            id="dealership"
            {...register("dealership", {
              required: {
                value: true,
                message: "Моля, изберете предпочитано представителство",
              },
            })}
          >
            <option value="" disabled selected>
              Моля, изберете
            </option>
            <option value="liulin2@motopfohe.bg">София Люлин</option>
            <option value="mladost@motopfohe.bg">София Младост</option>
            <option value="blagoevgrad@motopfohe.bg">Благоевград</option>
            <option value="burgas@motopfohe.bg">Бургас</option>
            <option value="varna@motopfohe.bg">Варна</option>
            <option value="velikotarnovo@motopfohe.bg">Велико Търново</option>
            <option value="gabrovo@motopfohe.bg">Габрово</option>
            <option value="dobrich@motopfohe.bg">Добрич</option>
            <option value="montana@motopfohe.bg">Монтана</option>
            <option value="pleven@motopfohe.bg">Плевен</option>
            <option value="plovdiv@motopfohe.bg">Пловдив</option>
            <option value="russe@motopfohe.bg">Русе</option>
            <option value="starazagora@motopfohe.bg">Стара Загора</option>
            <option value="smolyan@motopfohe.bg">Смолян</option>
            <option value="forsh@motopfohe.bg">Шумен</option>
          </select>
          <p className="text-red-500">{errors.dealership?.message}</p>
        </div>
        <button
          type="submit"
          className="px-16 py-2 mx-auto mt-8 text-white border rounded-full bg-fordBlue"
        >
          Изпрати
        </button>
      </form>
    </>
  );
}

export default MustangTestDrive;
